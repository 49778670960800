import { useCallback, useContext, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { MappingDefinition } from 'contexts/types';

interface TypeListProps {
    filter;
    setFilterValue;
}

export const TypeList = ({ filter, setFilterValue }: TypeListProps) => {
    const { types } = useContext<MappingDefinition>(MappingDefinitionsContext);
    const typeOptionsList: { label: string; value: number }[] = useMemo(
        () => types.map(t => ({ label: t.oemIqSectionName, value: t.oemIqSectionId })),
        [types]
    );

    const [inputValue, setInputValue] = useState((filter.valueList && filter.valueList[0]) || '');

    const handleChange = useCallback(
        e => {
            const v = e.target.value;
            const val = types.filter(t => t.oemIqSectionName.match(new RegExp(v, 'i')));
            setInputValue(v);
            setFilterValue({
                value: val,
                valueList: [v],
            });
        },
        [types, setFilterValue]
    );

    const [selectValue, setSelectValue] = useState(
        (filter.valueList && {
            value: types.find(t => t.oemIqSectionId === filter.value),
            label: filter.valueList[0],
        }) || {
            value: -1,
            label: '',
        }
    );

    const handleSelect = useCallback(
        v => {
            const val = types.find(t => t.oemIqSectionId === v.value);
            setSelectValue(v);
            setFilterValue({
                value: [val],
                valueList: [v.label],
            });
        },
        [types, setFilterValue]
    );

    return filter.operator.value !== operatorsList.contains.value &&
        filter.operator.value !== operatorsList.notContains.value ? (
        <ReactSelect
            className="mb-2 basic-single"
            classNamePrefix="select"
            placeholder="Choose value"
            aria-label="Choose value"
            options={typeOptionsList}
            isDisabled={!filter.id || !filter.operator}
            value={selectValue}
            onChange={handleSelect}
        />
    ) : (
        types?.length && (
            <input
                type="text"
                className="form-control"
                placeholder="Filter value"
                onChange={handleChange}
                value={inputValue}
            />
        )
    );
};

const operatorsList = {
    contains: {
        value: 'typeList.contains',
        label: 'contains',
    },
    notContains: {
        value: 'typeList.notContains',
        label: 'not contains',
    },
    eq: {
        value: 'typeList.eq',
        label: '==',
    },
    ne: {
        value: 'typeList.ne',
        label: '!=',
    },
};

export default {
    component: TypeList,
    operators: [operatorsList.contains, operatorsList.notContains, operatorsList.eq, operatorsList.ne],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};
