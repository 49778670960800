import { RuleCondition } from './FilterViewModel';

interface RuleConditionPillProps {
    ruleCondition: RuleCondition;
}

const RuleConditionPill = ({ ruleCondition }: RuleConditionPillProps) => {
    return (
        <span data-testid="rule-pill" className={`badge me-2 mt-2 pe-2 rule-label`}>
            <span data-testid="rule-pill-field">{ruleCondition.field.label}</span>
            <span data-testid="rule-pill-operator" className="badge rule-condition-pill">
                {ruleCondition.operator.label}
            </span>
            {ruleCondition.values.map(ruleValue => (
                <span data-testid="rule-pill-value" className="badge rule-pill" key={ruleValue.value}>
                    {ruleValue.label}
                </span>
            ))}
        </span>
    );
};

export default RuleConditionPill;
