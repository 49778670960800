import { CellProps } from 'components/Shared/Table/types';
import type { RefreshDashboardRowDataType } from '../types';

const BookSummaryCell: React.FC<CellProps> = ({ item }) => {
    const { isLoading, lastImportJobSummary: summary } = item as RefreshDashboardRowDataType;

    if (isLoading || !summary) return <td></td>;

    const id = `import-manager-item-${item.oemId}-books`;

    return (
        <td style={{ verticalAlign: 'middle' }} id={id}>
            <div>
                {summary.bookSummary
                    ? `${summary.bookSummary.new} new - ${summary.bookSummary.updated} updated`
                    : 'Waiting'}
            </div>
            <div>{summary.numberOfBooks === null ? '0' : summary.numberOfBooks} total</div>
        </td>
    );
};

export default BookSummaryCell;
