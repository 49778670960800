import { LargeCheckbox } from 'oemiq-common';

export const SelectAllHeaderCell = ({ isAll, onSelectAll }: { isAll: boolean; onSelectAll: () => unknown }) => {
    return (
        <LargeCheckbox
            id={'bulk-select-all'}
            label=""
            className="large-checkbox-check mb-0"
            checked={isAll}
            onChange={onSelectAll}
            readOnly={false}
        />
    );
};
