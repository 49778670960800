import { Component, ComponentType, MouseEventHandler } from 'react';
import { messages } from './Constants';

interface IWithButton extends React.HTMLAttributes<HTMLButtonElement> {
    isActive: boolean;
    label: string;
}

const withButton = <T extends object>(WrapperComponent: ComponentType<T>) => {
    return class WithButton extends Component<T & IWithButton> {
        render() {
            const { isActive, title, onClick, label, ...props } = this.props;

            return (
                <button
                    type="button"
                    className={`btn p-0 m-0 border-0 ${!isActive ? 'cursor-not-allowed' : ''}`}
                    disabled={!isActive}
                    title={!isActive ? title ?? messages.organizationDisabledActionNotAllowed : ''}>
                    <div className="action" onClick={onClick as MouseEventHandler<HTMLDivElement>}>
                        <WrapperComponent {...(props as T)} />
                        <span>{label}</span>
                    </div>
                </button>
            );
        }
    };
};

export default withButton;
