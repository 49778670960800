import { CellProps, ItemReturnEvent } from 'components/Shared/Table/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefreshPublisherDataRowType, RefreshPublisherTableActionTypes } from '../types';

const RefreshCell: React.FC<CellProps> = ({ item, index, idKey, cellEventCallback }) => {
    const { bookId, loadedAt } = item as RefreshPublisherDataRowType;
    const id = `reload-stats-book-${bookId}-button`;
    const handleClick = () => {
        const payload: ItemReturnEvent = {
            id: item[idKey] as string,
            item: item,
            index: index,
            actionId: RefreshPublisherTableActionTypes.RefreshBookStats,
        };

        cellEventCallback(payload);
    };

    return (
        <td>
            <div className="d-flex flex-row justify-content-start align-items-center">
                <div className="me-2">{loadedAt as string}</div>
                <button
                    id={id}
                    data-testid={id}
                    type="button"
                    onClick={handleClick}
                    className="btn btn-outline-primary"
                    title="Reload statistics">
                    <FontAwesomeIcon icon="arrows-rotate" />
                </button>
            </div>
        </td>
    );
};

export default RefreshCell;
