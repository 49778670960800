import { useContext } from 'react';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import ReactSelect from 'react-select';

type GroupListIdsComponentProps = {
    filter;
    setFilterValue;
};

const GroupListIdsComponent = ({ filter, setFilterValue }: GroupListIdsComponentProps) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);
    return (
        <ReactSelect
            className="mb-2"
            placeholder="Choose value"
            options={mappingDefinitions.groups}
            isMulti={true}
            isDisabled={!filter.id || !filter.operator}
            value={filter.value}
            getOptionLabel={v => v.value}
            onChange={value => setFilterValue({ value, valueList: value.map(v => v.value) })}
        />
    );
};

export default GroupListIdsComponent;
