import { ReactModal } from 'oemiq-common';
import useCompanyMove from './useCompanyMove';
import { MoveLocationModalProps } from './types';
import { USER_CANCEL_BUTTON_ID } from '../../ManageCustomersConstants';

const CompanyMoveModal = ({
    showMoveUserModal,
    handleShowMoveLocationModal,
    companyDetails,
    onSaveOrEdit,
}: MoveLocationModalProps) => {
    const {
        orgs,
        selectedOrg,
        setSelectedOrg,
        handleSave,
        saveEnabled,
        showConfirmModal,
        setShowConfirmModal,
        orgName,
        currentParentOrgName,
    } = useCompanyMove(handleShowMoveLocationModal, companyDetails, onSaveOrEdit);

    return (
        <>
            <ReactModal
                id="move-company-modal"
                headerComponent={<div>{'Move Location'}</div>}
                isOpen={showMoveUserModal}
                bodyComponent={
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column">
                            <span>
                                <b>Location:</b>
                            </span>
                            <span id="current-merge-org">{companyDetails.companyName}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <span>
                                <b>Current Parent Organization:</b>
                            </span>
                            <span id="current-merge-org">{currentParentOrgName}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <span>
                                <b>New Parent Organization</b>
                            </span>
                            <select
                                id="company-org"
                                className="form-control mt-2"
                                value={selectedOrg}
                                onChange={e => setSelectedOrg(parseInt(e.currentTarget.value))}>
                                <option value={0} disabled>
                                    Select
                                </option>
                                {orgs
                                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                                    .map(l => {
                                        if (l.organizationId === 4) return <></>;
                                        return (
                                            <option key={l.organizationId} value={l.organizationId}>
                                                {l.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                }
                footerComponent={
                    <div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-danger h-100"
                                id={USER_CANCEL_BUTTON_ID}
                                onClick={handleShowMoveLocationModal}>
                                Cancel
                            </button>
                            <button
                                id="move-user-modal-save"
                                type="button"
                                className="btn btn-primary ms-3 h-100"
                                onClick={() => setShowConfirmModal(true)}
                                disabled={!saveEnabled}>
                                <div className="d-flex">Move</div>
                            </button>
                        </div>
                    </div>
                }
            />
            <ReactModal
                id="confirm-user-move-modal"
                isOpen={showConfirmModal}
                headerComponent={<div>{'Are you sure?'}</div>}
                bodyComponent={
                    <div>
                        {'Are you sure, you want to move '}
                        <strong>{companyDetails.companyName}</strong>
                        {' into '}
                        <strong>{orgName}</strong>
                        {'?'}
                    </div>
                }
                footerComponent={
                    <div>
                        <button
                            id="move-user-cancel"
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setShowConfirmModal(false)}>
                            Cancel
                        </button>
                        <button
                            id="move-user-confirm"
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleSave}>
                            Confirm
                        </button>
                    </div>
                }
            />
        </>
    );
};

export default CompanyMoveModal;
