import { Route, Navigate, useLocation, RouteObject, redirect } from 'react-router-dom';
import { OemId } from 'helpers/OemId';

const redirectOems = [
    { from: OemId.Lexus, to: OemId.Toyota },
    { from: OemId.Acura, to: OemId.Honda },
    { from: OemId.Infiniti, to: OemId.Nissan },
    { from: OemId.RAM, to: OemId.Chrysler },
    { from: OemId.Fiat, to: OemId.Chrysler },
    { from: OemId.Dodge, to: OemId.Chrysler },
    { from: OemId.Jeep, to: OemId.Chrysler },
    { from: OemId.AlfaRomeo, to: OemId.Chrysler },
    { from: OemId.Audi, to: OemId.Volkswagen },
    { from: OemId.Genesis, to: OemId.Hyundai },
];

export const getRedirectOemRoute = (restOfPath = ''): RouteObject[] => {
    return redirectOems.map(ro => ({
        path: `${ro.from}${restOfPath ? '/' + restOfPath : ''}`,
        loader: ({ request }) => {
            return redirect(`../${ro.to}${restOfPath ? '/' + restOfPath : ''}${new URL(request.url).search}`);
        },
    }));
};

const RedirectOemRoute = (matchedPath: string, absolutePath: string, restOfPath = '') => {
    const location = useLocation();
    const queryString = location.search;

    return (
        <>
            {redirectOems.map((redirectOem, i) => {
                return (
                    <Route
                        key={i}
                        path={`${matchedPath}${redirectOem.from}${restOfPath}`}
                        element={
                            <Navigate replace to={`${absolutePath}${redirectOem.to}${restOfPath}${queryString}`} />
                        }
                    />
                );
            })}
        </>
    );
};

export default RedirectOemRoute;
