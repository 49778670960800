import { useState, useEffect, useContext, useCallback } from 'react';
import { requestBooksForOemApi } from 'api/RepairProcedureApi';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { OemId } from 'helpers/OemId';

const useLoadOemBooks = (oemId: OemId) => {
    const [books, setBooks] = useState([]);
    const { notifications } = useContext(NotificationsContext);

    const getBooks = useCallback(
        async (oemIds: OemId[]) => {
            try {
                const oemBooks = await Promise.all(oemIds.map(oemId => requestBooksForOemApi(oemId)));
                const data = oemBooks.flat();
                setBooks(data);
            } catch (error) {
                notifications.pushExceptionDanger(error);
            }
        },
        [notifications]
    );

    useEffect(() => {
        const oemIds = extendOemIdWithLuxuryOemIds(oemId);
        getBooks(oemIds);
    }, [oemId, getBooks]);

    return books;
};

const extendOemIdWithLuxuryOemIds = (oemId: OemId) => {
    switch (oemId) {
        case 1:
            return [1];
        case 2: // 2 = Chrysler, 28 = RAM, 29 = Fiat, 30 = Dodge, 31 = Jeep, 36 = Alfa Romeo
            return [2, 28, 29, 30, 31, 36];
        case 3:
            return [3, 6];
        case 4:
            return [4, 16];
        case 11:
            return [11];
        case 17:
            return [17, 18];
        case OemId.Hyundai:
            return [OemId.Hyundai, OemId.Genesis];
        case 26:
            return [26, 25];
        default:
            return isNaN(oemId) ? [] : [oemId];
    }
};

export default useLoadOemBooks;
