import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSelect from 'react-select';
import { FormatCurrency } from 'helpers/CurrencyHelper';
import ImagePreview from '../ImagePreview/ImagePreview';
import { partTypes } from 'helpers/PartTypes';

interface OneTimeUseFlagConvertProps {
    useOneTimeUseFlagConvert;
}

const OneTimeUseFlagConvert = ({ useOneTimeUseFlagConvert }: OneTimeUseFlagConvertProps) => {
    const {
        title,
        setTitle,
        quantity,
        setQuantity,
        quantityCondition,
        setQuantityCondition,
        partNumber,
        setPartNumber,
        price,
        setPrice,
        partTypeId,
        setPartTypeId,
        isSelectingImg,
        setIsSelectingImage,
        selectedImages,
        text,
        setText,
        note,
        setNote,
        handleRemoveImageClick,
        handleSelectTagRegion,
        handleRevertRegion,
        convertFlagToTag,
        isTitleBlank,
        isChanged,
        calcConditions,
    } = useOneTimeUseFlagConvert;

    return (
        <div className="tag-details px-2">
            <hr />
            <input
                type="text"
                placeholder="Tag Name"
                className="form-control form-control-sm"
                value={title}
                onChange={e => setTitle(e.currentTarget.value)}></input>
            <input
                type="text"
                placeholder="Quantity"
                className="form-control form-control-sm mt-2"
                value={quantity}
                onChange={e =>
                    setQuantity(isNaN(parseInt(e.currentTarget.value)) ? '' : e.currentTarget.value)
                }></input>
            <ReactSelect
                defaultValue={calcConditions.find(c => c.value === quantityCondition)}
                options={calcConditions}
                onChange={e => setQuantityCondition(e.value)}
            />
            <input
                type="text"
                placeholder="Part Number"
                className="form-control form-control-sm mt-2"
                value={partNumber}
                onChange={e => setPartNumber(e.currentTarget.value)}
            />
            <input
                type="text"
                placeholder="Part Price"
                className="form-control form-control-sm mt-2"
                value={price}
                step="0.01"
                onChange={e => setPrice(e.currentTarget.value)}
                onBlur={() => setPrice(FormatCurrency(price, 2))}
            />
            <select
                id="partType"
                className="form-select form-control-sm mt-2"
                value={partTypeId}
                onChange={e => setPartTypeId(e.currentTarget.value)}>
                <option value="">Select Part Type</option>
                {partTypes.map((p, index) => (
                    <option key={index} value={p.value}>
                        {p.text}
                    </option>
                ))}
            </select>
            <div className="mt-2">
                <div>Activate desired command button and click image to add/remove images</div>
                <button
                    type="button"
                    className={`btn btn-sm ${isSelectingImg ? 'btn-primary' : 'btn-secondary'} mt-2`}
                    onClick={() => setIsSelectingImage(!isSelectingImg)}>
                    Select Images: {isSelectingImg ? 'On' : 'Off'}
                </button>
                <div className="mt-2">
                    {selectedImages.map((img, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            <span style={{ position: 'absolute', top: -5, left: 10 }}>
                                <FontAwesomeIcon
                                    className="clickable"
                                    icon="times-circle"
                                    onClick={() => handleRemoveImageClick(img)}
                                />
                            </span>
                            <ImagePreview alt="" img={img} width={150} />
                        </div>
                    ))}
                </div>
            </div>
            <textarea
                rows={4}
                className="form-control form=control-sm mt-2"
                placeholder="Tag Text"
                value={text}
                onChange={e => setText(e.currentTarget.value)}></textarea>
            <div className="mt-2">
                <div>
                    Highlight the region in the document on the left, and then press &lsquo;Update Tag
                    Region/Text&rsquo;
                </div>
                <button
                    type="button"
                    className={'btn btn-sm btn-primary mt-2 me-2'}
                    onClick={() => handleSelectTagRegion()}>
                    Update Tag Region/Text
                </button>
                <button type="button" className={'btn btn-sm btn-secondary mt-2'} onClick={() => handleRevertRegion()}>
                    Revert
                </button>
            </div>
            <textarea
                rows={2}
                className="form-control form-control-sm mt-2"
                id="note"
                placeholder="Note"
                value={note}
                onChange={e => setNote(e.currentTarget.value)}></textarea>
            <div className="mt-2 d-flex justify-content-end">
                <button
                    type="button"
                    className="btn btn-sm btn-primary me-2"
                    onClick={() => convertFlagToTag()}
                    disabled={!isChanged || isTitleBlank}>
                    Convert to tag
                </button>
            </div>
        </div>
    );
};

export default OneTimeUseFlagConvert;
