import { useContext, useEffect, useState } from 'react';
import type { CellProps, ItemReturnEvent } from 'components/Shared/Table/types';
import { RefreshDashboardRowDataType, RefreshDashboardActionTypes } from '../types';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { Link } from 'react-router-dom';
import { requestImportJobHistoryByOemId } from 'api/RepairProcedureApi';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';

const ActionsCell: React.FC<CellProps> = ({ item, index, idKey, cellEventCallback }) => {
    const {
        isLoading,
        lastImportJobSummary: summary,
        oemRefreshSchedules,
        oemId,
    } = item as RefreshDashboardRowDataType;
    const { hasAccess } = useContext(AccessControlContext);
    const { notifications } = useContext(NotificationsContext);
    const [lastImportJobId, setLastImportJobId] = useState(null);
    const [isDetailsLoading, setIsDetailsLoading] = useState(false);

    useEffect(() => {
        const fetchLastImportJobBookId = async () => {
            try {
                setIsDetailsLoading(true);
                const lastImportJobs = await requestImportJobHistoryByOemId(oemId, 1);
                if (lastImportJobs.length === 0) {
                    return null;
                }

                const lastImportJob = lastImportJobs[0];
                setLastImportJobId(lastImportJob?.importJobId);
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                setIsDetailsLoading(false);
            }
        };

        fetchLastImportJobBookId();
    }, [notifications, oemId]);

    const buttonHandlerFactory = (actionId: string, id?: number | null | undefined): (() => void) => {
        return (): void => {
            const payload: ItemReturnEvent = {
                id: id?.toString() ?? (item[idKey] as string),
                item: item,
                index: index,
                actionId: actionId,
            };

            cellEventCallback(payload);
        };
    };

    const id = `import-manager-item-${item.oemId}-actions`;
    const refreshId = `${id}-refresh`;
    const detailsId = `${id}-details`;
    const historyId = `${id}-history`;

    const HistoryLink = () => (
        <Link id={historyId} data-testid={historyId} className="btn btn-link mx-3" to={`${item[idKey]}`}>
            History
        </Link>
    );

    const DetailsLink = () => (
        <Link
            id={detailsId}
            data-testid={detailsId}
            className="btn btn-link mx-5"
            to={`${item[idKey]}/${lastImportJobId}`}>
            Details
        </Link>
    );

    const DetatilsAndHistoryComponent = () => (
        <div className="flex-grow-1">
            {!isDetailsLoading && <DetailsLink />}
            <HistoryLink />
        </div>
    );

    if (isLoading) {
        return (
            <td className="px-0" style={{ verticalAlign: 'middle' }} id={id}>
                <DetatilsAndHistoryComponent />
            </td>
        );
    }

    return (
        <td className="px-0" style={{ verticalAlign: 'middle' }} id={id}>
            <div className="d-flex flex-row align-items-center justify-content-center py-2">
                {!summary ? (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={buttonHandlerFactory(RefreshDashboardActionTypes.ReloadOemStatistics)}
                        title="Reload this OEM">
                        Reload
                    </button>
                ) : (
                    <>
                        {hasAccess('importManager.runRefresh') && (
                            <div className="d-flex flex-column flex-grow-1">
                                {oemRefreshSchedules.length > 0 ? (
                                    oemRefreshSchedules.map((schedule, index) => {
                                        const IS_TESTING_RECORD = schedule.oemId === 100;
                                        const refreshScheduleId = `${id}-${schedule.id}`;
                                        return (
                                            <div
                                                key={schedule.id}
                                                className={`d-flex justify-content-end py-4 ${
                                                    index === 0 ? 'border-0' : 'border-top'
                                                }`}>
                                                <button
                                                    id={refreshScheduleId}
                                                    key={schedule.id}
                                                    disabled={IS_TESTING_RECORD}
                                                    type="button"
                                                    className={`btn ${
                                                        summary.success === null ? 'btn-warning' : 'btn-primary'
                                                    } btn-sm mx-3`}
                                                    onClick={buttonHandlerFactory(
                                                        RefreshDashboardActionTypes.RunRefresh,
                                                        schedule.id
                                                    )}>
                                                    {IS_TESTING_RECORD ? 'Testing Record' : 'Run Refresh'}
                                                </button>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="d-flex justify-content-end py-4 border-0">
                                        <button
                                            id={refreshId}
                                            type="button"
                                            className="btn btn-secondary btn-sm mx-3"
                                            disabled={true}>
                                            Run Refresh
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        <DetatilsAndHistoryComponent />
                    </>
                )}
            </div>
        </td>
    );
};

export default ActionsCell;
