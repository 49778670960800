import { useCallback, useContext, useEffect, useState } from 'react';
import { ReactModal, PrimaryButton } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatDateToGMT } from 'helpers/DateHelper';
import { requestMappingOperations } from 'api/RepairProcedures/RepairProcedureOperationApi';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { OemId } from 'helpers/OemId';
import { requestOrganizationUsers } from 'api/SecurityApi';
import CellOperationStatus from './cells/CellOperationStatus';
import CellUser from './cells/CellUser';
import cellOperationAction from './cells/cellOperationAction';
import { match } from 'ts-pattern';
import cellNumberOfDisposedFlags from './cells/cellNumberOfDisposedFlags';
import { ApplyFlagDispositionRulesData, Operation } from './Operation';

export enum OperationSite {
    RulesRunner,
    FlagDispositionRules,
}

export enum OperationTypes {
    None,
    Import,
    ApplyRule,
    UndoRule,
    ApplyFlagDispositionRules,
}

const getModalTitle = (modalType: OperationSite) =>
    match(modalType)
        .with(OperationSite.RulesRunner, () => 'Rules Runner Operations')
        .with(OperationSite.FlagDispositionRules, () => 'Flag Disposition Rules Operations')
        .otherwise(() => '');

type ModalOperationsProps = {
    oemId: OemId;
    operationTypes: OperationTypes[];
    modalType: OperationSite;
    isOperationsModalOpen: boolean;
    closeOperationsModalCallback: () => void;
};

const ModalOperations = ({
    oemId,
    operationTypes,
    modalType,
    isOperationsModalOpen,
    closeOperationsModalCallback,
}: ModalOperationsProps) => {
    const { notifications } = useContext(NotificationsContext);
    const { userInfo } = useContext(AccessControlContext);

    const [operationsData, setOperationsData] = useState<Operation<unknown>[]>([]);
    const [organizationUsers, setOrganizationUsers] = useState([]);

    const getOperations = useCallback(async () => {
        try {
            const data = await requestMappingOperations(oemId, operationTypes);
            setOperationsData(data);
            return true;
        } catch (error) {
            notifications.pushExceptionDanger(error);
            return false;
        }
    }, [oemId, operationTypes, notifications]);

    const getOrganizationUsers = useCallback(async () => {
        try {
            const data = await requestOrganizationUsers(userInfo.organization.organizationId);
            setOrganizationUsers(data);
        } catch (error) {
            notifications.pushExceptionDanger(error);
        }
    }, [userInfo, notifications]);

    useEffect(() => {
        getOrganizationUsers();
        getOperations();
    }, [getOperations, getOrganizationUsers]);

    const reloadOperationsData = useCallback(async () => {
        (await getOperations()) && notifications.pushSuccess('Rules operations reloaded');
    }, [getOperations, notifications]);

    return (
        <ReactModal
            id="modal-operations"
            isOpen={isOperationsModalOpen}
            toggle={closeOperationsModalCallback}
            headerComponent={
                <div className="d-flex justify-content-between">
                    <span>{getModalTitle(modalType)}</span>
                    <span className="text-right pe-3">
                        <small>Items: {operationsData.length}</small>
                    </span>
                </div>
            }
            bodyComponent={
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Action</th>
                            <th scope="col" className="text-center">
                                User
                            </th>
                            <th scope="col" className="text-center">
                                Status
                            </th>
                            {modalType === OperationSite.FlagDispositionRules && (
                                <th scope="col" className="text-center">
                                    Number of Disposed Flags
                                </th>
                            )}
                            <th scope="col" className="text-center">
                                Create Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {operationsData.map(operation => (
                            <tr key={operation.id} className={operation.error || operation.subError ? 'bg-danger' : ''}>
                                <td>{cellOperationAction(operation)}</td>
                                <td className="text-center">
                                    <CellUser users={organizationUsers} userId={operation.userId} />
                                </td>
                                <td className="text-center">
                                    <CellOperationStatus status={operation.status} />
                                </td>
                                {modalType === OperationSite.FlagDispositionRules && (
                                    <td className="text-center">
                                        {cellNumberOfDisposedFlags(
                                            operation as Operation<ApplyFlagDispositionRulesData>
                                        )}
                                    </td>
                                )}
                                <td className="text-center">{FormatDateToGMT(operation.createDate)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
            footerComponent={
                <>
                    <PrimaryButton id="modal-operations-reload" type="button" onClick={reloadOperationsData}>
                        Reload <FontAwesomeIcon className="ms-1" icon="repeat" />
                    </PrimaryButton>
                    <PrimaryButton
                        id="modal-operations-close"
                        className="me-3"
                        type="button"
                        onClick={closeOperationsModalCallback}>
                        Close
                    </PrimaryButton>
                </>
            }
            className={'modal-lg sticky-footer'}
        />
    );
};

export default ModalOperations;
