import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import Authenticated from 'auth/Authenticated';
import { ApplicationPaths } from 'auth/AuthenticationConstants';
import { Login, LoginCallback, LoginFailed } from 'auth/Login';
import HomePage from 'components/locations/Home/HomePage';
import { ManageCustomersRoute } from 'components/locations/ManageCustomers/ManageCustomersRoutes';
import { MappingProcessRoute } from 'components/locations/MappingProcess/MappingProcessRoute';
import { OtherRoute } from 'components/locations/Other/OtherRoutes';
import { RefreshManagerRoute } from 'components/locations/RefreshManager/RefreshManagerRoutes';
import { TaggingProcessRoute } from 'components/locations/TaggingProcess/TaggingProcessRoutes';
import { VehicleProcessRoute } from 'components/locations/VehicleProcess/VehicleProcessRoutes';
import AccessControl from 'components/Shared/AccessControl/AccessControl';
import PageNotFound from 'components/Shared/ErrorPages/PageNotFound';
import ToastProvider from 'components/ToastProvider';
import Toast from 'components/ToastProvider/Toast';
import { ToastType } from 'components/ToastProvider/ToastType';
import PerformanceCheckProvider from 'contexts/PerformanceCheckContextProvider';
import GoogleAnalytics from 'ga/GoogleAnalytics';
import useFontAwesomeIcons from 'hooks/useFontAwesomeIcons';
import { useState } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { AuthorizedElementMatches } from 'routes/AuthorizedElementMatches';
import LegacyRoutes from 'routes/LegacyRoutes';
import { reactPlugin } from './api/config/AppInsights';
import Layout from './components/Layout';
import { FeatureFlagsProvider } from 'contexts/FeatureFlagsContext';

const Root = () => {
    // eslint-disable-next-line no-empty-pattern
    const {} = useFontAwesomeIcons(); //Imports Font Awesome Icons to avoid huge import statement in App.jsx
    const [open, setOpen] = useState(false);

    return (
        <AppInsightsErrorBoundary
            onError={() => {
                setOpen(true);
                return (
                    <Toast
                        isOpen={open}
                        header={'Error'}
                        type={ToastType.ERROR}
                        message={'An unexpected error has occurred! Please refresh the page.'}
                        toggle={() => setOpen(false)}
                    />
                );
            }}
            appInsights={reactPlugin}>
            <ToastProvider>
                <Outlet />
            </ToastProvider>
        </AppInsightsErrorBoundary>
    );
};

const router = createBrowserRouter([
    {
        element: <Root />,
        children: [
            { path: ApplicationPaths.Login, element: <Login /> },
            { path: ApplicationPaths.LoginFailed, element: <LoginFailed /> },
            { path: ApplicationPaths.LoginCallback, element: <LoginCallback /> },
            {
                element: (
                    <Authenticated>
                        <AccessControl>
                            <FeatureFlagsProvider>
                                <Layout>
                                    <PerformanceCheckProvider>
                                        <AuthorizedElementMatches>
                                            <Outlet />
                                        </AuthorizedElementMatches>
                                    </PerformanceCheckProvider>
                                </Layout>
                                <GoogleAnalytics />
                            </FeatureFlagsProvider>
                        </AccessControl>
                    </Authenticated>
                ),
                children: [
                    { index: true, element: <HomePage /> },
                    MappingProcessRoute,
                    TaggingProcessRoute,
                    VehicleProcessRoute,
                    RefreshManagerRoute,
                    ManageCustomersRoute,
                    OtherRoute,
                    {
                        path: 'mapper-old/*',
                        element: <LegacyRoutes />,
                    },
                    {
                        path: 'tagger/*',
                        element: <LegacyRoutes />,
                    },
                    {
                        path: '*',
                        element: <PageNotFound />,
                    },
                ],
            },
        ],
    },
]);

const App = () => {
    return <RouterProvider router={router} />;
};

export default App;
