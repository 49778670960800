import { ReactModal } from 'oemiq-common';
import ReactSelect from 'react-select';
import { USER_CANCEL_BUTTON_ID } from '../ManageCustomersConstants';
import useMoveUser from './useMoveUser';
import { MoveUserModalProps } from './types';

const MoveUserModal = ({
    showMoveUserModal,
    handleShowMoveUserModal,
    userDetails,
    onSaveOrEdit,
}: MoveUserModalProps) => {
    const {
        orgs,
        selectedOrg,
        setSelectedOrg,
        selectedPrimaryLocation,
        orgLocations,
        handleSetPrimaryLocation,
        multiSelectOptions,
        selectedLocations,
        handleLocationsChange,
        handleSave,
        saveEnabled,
        disableLocationSelection,
        showConfirmModal,
        setShowConfirmModal,
    } = useMoveUser(handleShowMoveUserModal, userDetails, onSaveOrEdit);

    return (
        <>
            <ReactModal
                id="move-user-modal"
                headerComponent={<div>{"Change User's Organization and Location"}</div>}
                isOpen={showMoveUserModal}
                bodyComponent={
                    <div>
                        <select
                            id="user-org"
                            className="form-control mt-2"
                            value={selectedOrg}
                            onChange={e => setSelectedOrg(parseInt(e.currentTarget.value))}>
                            <option value={0} disabled>
                                Choose Organization (Required)
                            </option>
                            {orgs
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map(l => {
                                    return (
                                        <option key={l.organizationId} value={l.organizationId}>
                                            {l.name}
                                        </option>
                                    );
                                })}
                        </select>
                        <select
                            id="user-primary-location"
                            className="form-control mt-2"
                            value={selectedPrimaryLocation}
                            disabled={disableLocationSelection}
                            onChange={e => handleSetPrimaryLocation(e)}
                            placeholder="Choose Primary Location (Required)">
                            <option value={0}>Choose Primary Location (Required)</option>
                            {orgLocations?.map(l => {
                                return (
                                    <option key={l.companyId} value={l.companyId}>
                                        {l.companyName}
                                    </option>
                                );
                            })}
                        </select>
                        <ReactSelect
                            id="user-secondary-locations"
                            className="mt-2"
                            placeholder="Select Secondary Locations"
                            isMulti={true}
                            options={multiSelectOptions}
                            value={selectedLocations}
                            onChange={e => handleLocationsChange(e)}
                            isClearable={false}
                            isDisabled={disableLocationSelection}
                        />
                    </div>
                }
                footerComponent={
                    <div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-danger h-100"
                                id={USER_CANCEL_BUTTON_ID}
                                onClick={handleShowMoveUserModal}>
                                Cancel
                            </button>
                            <button
                                id="move-user-modal-save"
                                type="button"
                                className="btn btn-primary ms-3 h-100"
                                onClick={() => setShowConfirmModal(true)}
                                disabled={!saveEnabled}>
                                <div className="d-flex">Save</div>
                            </button>
                        </div>
                    </div>
                }
            />
            <ReactModal
                id="confirm-user-move-modal"
                isOpen={showConfirmModal}
                headerComponent={<div>{'Are you sure?'}</div>}
                bodyComponent={
                    <div>
                        {
                            'Are you sure you want to move this user to another organization? All previously created plans will no longer be accessible by the user.'
                        }
                    </div>
                }
                footerComponent={
                    <div>
                        <button
                            id="move-user-cancel"
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setShowConfirmModal(false)}>
                            Cancel
                        </button>
                        <button
                            id="move-user-confirm"
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleSave}>
                            Confirm
                        </button>
                    </div>
                }
            />
        </>
    );
};

export default MoveUserModal;
