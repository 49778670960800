import Table from 'components/Shared/Table/Table';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import useImportHistory from './useImportHistory';
import InfiniteScrollingFooter from '../shared/InfiniteScrollingFooter';
import SmallSpinner from 'components/SmallSpinner';
import Confirmation from 'components/Shared/Confirmation/Confirmation';

const ImportHistory: React.FC = () => {
    const {
        loading,
        data,
        oem,
        headers,
        searchValue,
        handleSearchValueChange,
        handleSearchKeyDown,
        handleSearchBlur,
        fetchMore,
        total,
        handlers,
        confirmationRef,
    } = useImportHistory();

    return (
        <>
            <div className="d-flex flex-row align-items-center">
                <div className="mx-5">
                    {oem && <img width="64px" src={oem.logo} alt={oem.oemName} title={oem.oemName} />}
                </div>
                <div style={{ flexGrow: 1 }}>
                    <SearchBar
                        value={searchValue}
                        disabled={loading}
                        onChange={handleSearchValueChange}
                        onKeyDown={handleSearchKeyDown}
                        onBlur={handleSearchBlur}
                    />
                </div>
            </div>
            <Table
                className="mb-5"
                idKey="importJobId"
                data={data}
                headers={headers}
                cellEventCallbacks={handlers}
                tableHtmlId="import-manager-history"
            />
            <Confirmation ref={confirmationRef} />
            <InfiniteScrollingFooter loading={loading} fetchMore={fetchMore} hasMoreData={total > data.length} />
            <footer className="d-flex flex-row fixed-bottom" style={{ minHeight: '35px' }}>
                {!loading ? (
                    <div className="m-2">{`Showing ${data.length} of ${total} items`}</div>
                ) : (
                    <div className="m-2">
                        <SmallSpinner id="loading-spinner" />
                    </div>
                )}
            </footer>
        </>
    );
};

export default ImportHistory;
