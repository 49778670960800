import { FC, useState, useEffect, useCallback } from 'react';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import { TextArea } from 'oemiq-common';
import { CellProps, TextAreaCellOptions } from '../types';

const IngestionNotes: (options?: TextAreaCellOptions) => FC<CellProps> = options => {
    const IngestionNotesComponent = ({ header, item, index }: CellProps) => {
        const [note, setNote] = useState(item.notes ? item.notes : '');

        // Determine the ID based on the available properties
        const determineId = useCallback(() => {
            if (item.fordScrapeManagerVehicleId != null) {
                return item.fordScrapeManagerVehicleId;
            } else if (item.importPublicationId != null) {
                return item.importPublicationId;
            } else {
                return item.id;
            }
        }, [item.fordScrapeManagerVehicleId, item.importPublicationId, item.id]);
        const [id, setId] = useState(determineId());

        useEffect(() => {
            setNote(item.notes ? item.notes : '');
            setId(determineId()); // Update ID on item change
        }, [determineId, item]);

        const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
            options?.textArea.onBlur(id as number, e.target.value);
        };

        const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setNote(e.target.value);
        };

        return (
            <td
                headers={header.id}
                className={calculateClass(options, item)}
                id={`${header.id}${index}`}
                title={header.title}
                key={`${id}${header}`}>
                {item.bookId ? (
                    <></>
                ) : (
                    <TextArea
                        id={'form-control-input-note' + id}
                        className={'refresh-ingestion-manager-textarea custom-textarea-scroll'}
                        rows={1}
                        placeholder="Add notes not exceeding 60 characters"
                        value={note}
                        onChange={handleOnChange}
                        textAreaProps={{
                            onBlur: handleBlur,
                            maxLength: 60,
                        }}
                    />
                )}
            </td>
        );
    };
    return IngestionNotesComponent;
};

export default IngestionNotes;
