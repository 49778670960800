import { IBulkSendConfirmationModalBody } from '../types';

function BulkSendConfirmationModalBody({ selectedUsers }: IBulkSendConfirmationModalBody) {
    return (
        <div className="bulk-send-confirmation-modal-body">
            Send activation email to the following recipients?
            <ul className="users-list">
                {selectedUsers.map(s => {
                    const { firstName, lastName, email } = s.item;
                    return <li key={s.id}>{firstName + ' ' + lastName + ' - ' + email}</li>;
                })}
            </ul>
        </div>
    );
}

export default BulkSendConfirmationModalBody;
