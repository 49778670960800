import { createContext, useContext, ReactNode } from 'react';
import { useFetchVehicleInfo, VehicleInfo } from './useFetchVehicleInfo';

const VehicleInfoContext = createContext<VehicleInfo | null>(null);

export const VehicleInfoProvider = ({ children }: { children: ReactNode }) => {
    const vehicleInfo = useFetchVehicleInfo();
    return <VehicleInfoContext.Provider value={vehicleInfo}>{children}</VehicleInfoContext.Provider>;
};

export const useVehicleInfo = () => {
    const context = useContext(VehicleInfoContext);
    if (!context) {
        throw new Error('useVehicleInfoContext must be used within a VehicleInfoProvider');
    }
    return context;
};
