interface SmallSpinnerPropsType {
    className?: string;
    text?: string;
    id?: string;
    'data-testid'?: string;
}

const SmallSpinner: React.FC<SmallSpinnerPropsType> = ({ className, text, ...rest }) => {
    return (
        <div className={`d-flex justify-content-center ${className ? className : ''}`} {...rest}>
            <div className="spinner-border spinner-border-sm" role="status"></div>
            {!!text && <div className="mx-2">{text}</div>}
        </div>
    );
};

export default SmallSpinner;
