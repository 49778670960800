import { FC } from 'react';
import { CellProps, BasicCellOptions, DataItem } from 'components/Shared/Table/types';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import './ruleLabels.scss';

export interface RuleConditionItem {
    labelText: string;
    ruleConditionPillText?: string;
    valuePillText: string;
}

interface RuleConditionLabelsProps extends BasicCellOptions {
    labels?: (i: DataItem) => RuleConditionItem[];
}

const displayRuleValue = (value: string) => (value === '' ? <i className="empty-value">{'empty_value'}</i> : value);

const RuleConditionLabels: (options?: RuleConditionLabelsProps) => FC<CellProps> = options => {
    const RuleConditionLabelsComponent = ({ item, header }: CellProps) => {
        return (
            <td headers={header.id} className={calculateClass(options, item)}>
                {options.labels &&
                    options.labels(item).map((l, i) => (
                        <span
                            key={i}
                            className="badge me-2 mt-2 pe-2 rule-label"
                            style={{ verticalAlign: 'middle', fontSize: '0.9rem' }}>
                            {l.labelText}
                            {l.ruleConditionPillText === 'Contains' && (
                                <span className="badge rule-condition-pill">contains</span>
                            )}
                            {l.ruleConditionPillText === 'NotContains' && (
                                <span className="badge rule-condition-pill">not contains</span>
                            )}
                            <span className="badge rule-pill" title={l.valuePillText}>
                                {displayRuleValue(l.valuePillText)}
                            </span>
                        </span>
                    ))}
            </td>
        );
    };
    return RuleConditionLabelsComponent;
};

export default RuleConditionLabels;
