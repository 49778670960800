import { Text } from './Text';

type ProcedureHTMLProps = {
    filter;
    setFilterValue;
};

export const ProcedureHTML = ({ filter, setFilterValue }: ProcedureHTMLProps) => {
    return <Text filter={filter} setFilterValue={setFilterValue}></Text>;
};

const operatorsList = {
    search: {
        value: 'search',
        label: 'search',
    },
};
export default {
    component: ProcedureHTML,
    validator: v => v,
    operators: [operatorsList.search],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};
