import { IUsersActivationEmailFooter } from '../types';

const UsersActivationEmailFooter = ({ show, onSend, disabled }: IUsersActivationEmailFooter) => {
    if (!show) return null;

    return (
        <footer className="d-flex justify-content-end fixed-bottom">
            <button type="button" className="btn btn-success m-1" onClick={onSend} disabled={disabled}>
                Bulk Activation Email
            </button>
        </footer>
    );
};

export default UsersActivationEmailFooter;
