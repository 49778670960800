import { FC } from 'react';
import { CellProps, TextCellOptions } from '../types';
import calculateClass from './Helpers/calculateClass';

const Text: (options?: TextCellOptions) => FC<CellProps> = options => {
    const TextComponent = ({ value, header, item, index }: CellProps) => (
        <td
            headers={header.id}
            className={calculateClass(options, item)}
            id={`${header.id}${index}`}
            title={header.title}>
            {options?.transform ? options.transform(item) : value}
        </td>
    );
    return TextComponent;
};
export default Text;
