import { ProcedureType } from 'hooks/OemModels/MetaModel/ProcedureType';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import { useOemIdParams } from 'components/locations/MappingProcess/RulesRunner/RulesRunnerTool';
import classNames from 'classnames';

export const MappingToolBreadcrumb = ({ procedureType }: { procedureType?: ProcedureType | null }) => {
    const oemId = useOemIdParams();
    const buildUrl = (pt: ProcedureType | null) =>
        `/mapping-process/mapper/${oemId}/${pt ? `${kebabCase(pt)}s` : 'all'}`;

    return (
        <Dropdown>
            <Link
                to={buildUrl(procedureType)}
                className={classNames('text-decoration-none', 'text-white', 'text-nowrap')}>
                {procedureType ?? 'All'}
            </Link>
            <Dropdown.Toggle split as={'span'} className="clickable text-white me-2" />
            <Dropdown.Menu>
                <Dropdown.Item to={buildUrl(null)} as={Link} active={procedureType === null}>
                    All
                </Dropdown.Item>
                <Dropdown.Item
                    to={buildUrl(ProcedureType.Procedure)}
                    as={Link}
                    active={procedureType === ProcedureType.Procedure}>
                    Procedures
                </Dropdown.Item>
                <Dropdown.Item
                    to={buildUrl(ProcedureType.Bulletin)}
                    as={Link}
                    active={procedureType === ProcedureType.Bulletin}>
                    Bulletins
                </Dropdown.Item>
                <Dropdown.Item
                    to={buildUrl(ProcedureType.PositionStatement)}
                    as={Link}
                    active={procedureType === ProcedureType.PositionStatement}>
                    Position statements
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
