import { useEffect, useState } from 'react';
import { NumberOfFlags } from '../types';
import { isNil } from 'lodash';

export const FlaggerToolNumberOfFlags = ({ id, getNumberOfFlags, handleNavigateToTaggerClick }: NumberOfFlags) => {
    const [oneTimeUseFlags, setOneTimeUseFlags] = useState<number | null>(null);

    useEffect(() => {
        const fetchNumberOfFlagsData = async () => {
            const result = await getNumberOfFlags(Number(id));
            setOneTimeUseFlags(result);
        };
        fetchNumberOfFlagsData();
    }, [getNumberOfFlags, id]);

    return (
        <>
            {isNil(oneTimeUseFlags) ? (
                <div className="d-flex justify-content-start">
                    <div className="spinner-border spinner-border-sm" role="status"></div>
                </div>
            ) : (
                <a style={{ color: '#095792', cursor: 'pointer' }} onClick={handleNavigateToTaggerClick}>
                    {oneTimeUseFlags}
                </a>
            )}
        </>
    );
};
