import type { CellProps } from 'components/Shared/Table/types';
import type { ImportJobDetailsRowDataType } from '../types';

const BookCell: React.FC<CellProps> = ({ item }) => {
    const { rpBookId, bookFriendlyName, importJobBookId } = item as ImportJobDetailsRowDataType;
    const text = rpBookId ? bookFriendlyName : 'Awaiting Processing';
    const title = rpBookId ? `${rpBookId} - ${importJobBookId}` : null;
    return <td title={title}>{text}</td>;
};

export default BookCell;
