import { FC } from 'react';
import { CellProps, NumberCellOptions } from '../types';
import calculateClass from './Helpers/calculateClass';

const Number: (options?: NumberCellOptions) => FC<CellProps> = options => {
    const NumberComponent = ({ value, header, item }: CellProps) => {
        const isHighlight = options?.highlightFn ? options.highlightFn(item) : false;
        return (
            <td headers={header.id} className={`${calculateClass(options, item)} text-center`} title={header.title}>
                {isHighlight ? <strong>{value}</strong> : value}
            </td>
        );
    };
    return NumberComponent;
};

export default Number;
