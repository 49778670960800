import { FC } from 'react';
import { Select } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ClearableDropDown.scss';
import { IClearableDropDownProps } from '../types';

const ClearableDropDown: FC<IClearableDropDownProps> = ({
    id,
    value,
    options,
    disabled,
    label,
    defaultOption,
    className,
    formGroupClassName,
    style,
    selectProps,
    fullWidth,
    onClear,
    onChange,
}) => {
    //show clear when value exists and dont show when value is equal to the default option
    const shouldShowClearIcon = value != null && (!defaultOption || value !== defaultOption.value);
    return (
        <div className="position-relative">
            <Select
                id={id}
                value={value}
                onChange={onChange}
                options={options}
                disabled={disabled}
                label={label}
                defaultOption={defaultOption}
                className={className}
                formGroupClassName={formGroupClassName}
                style={style}
                selectProps={selectProps}
                fullWidth={fullWidth}
            />
            {shouldShowClearIcon && (
                <button type="button" className="ddl-clearable-btn" onClick={onClear} aria-label="Clear selection">
                    <FontAwesomeIcon className="clickable" icon={'close'} />
                </button>
            )}
        </div>
    );
};

export default ClearableDropDown;
