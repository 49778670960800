import { LightButton, PrimaryButton } from 'oemiq-common';

interface EditScheduleModalFooterProps {
    saveButtonDisabled: boolean;
    onSave: () => void;
    onCancel: () => void;
}

const EditScheduleModalFooter: React.FC<EditScheduleModalFooterProps> = ({ saveButtonDisabled, onCancel, onSave }) => {
    return (
        <div className="d-flex flex-row">
            <div className="mx-3">
                <LightButton
                    id="cancel-button-edit-refresh-schedule-modal"
                    data-testid="cancel-button-edit-refresh-schedule-modal"
                    className="btn-sm"
                    onClick={onCancel}>
                    Cancel
                </LightButton>
            </div>
            <div className="mx-3">
                <PrimaryButton
                    id="save-button-edit-refresh-schedule-modal"
                    data-testid="save-button-edit-refresh-schedule-modal"
                    disabled={saveButtonDisabled}
                    onClick={onSave}>
                    Save
                </PrimaryButton>
            </div>
        </div>
    );
};

export default EditScheduleModalFooter;
