import { useCallback, useEffect, useMemo, useState } from 'react';
import SignalRHub from 'hooks/signalR/SignalRHub';
import './MappingRulesRunIndicator.scss';
import { requestIsOperationsAreRunning } from 'api/RepairProcedureApi';

const MappingRulesRunIndicator = () => {
    const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);

    const binaryGibber = useMemo(
        () =>
            [...Array(400).keys()].reduce(
                (p, c) => `${p}${Math.round(Math.random())}${!((c + 1) % 20) ? ' ' : ''}`,
                ''
            ),
        []
    );

    const checkIfOperationsAreRunning = useCallback(async () => {
        const isOperationRunning = await requestIsOperationsAreRunning();
        setIsIndicatorVisible(isOperationRunning);
    }, []);

    const callbacks = useMemo(
        () => ({
            onApplyMappingRuleStarted: () => checkIfOperationsAreRunning(),
            onApplyMappingRuleFinished: () => checkIfOperationsAreRunning(),
        }),
        [checkIfOperationsAreRunning]
    );

    useEffect(() => {
        checkIfOperationsAreRunning();
    }, [checkIfOperationsAreRunning]);

    return (
        <SignalRHub hub={'mappingRules'} callbacks={callbacks}>
            {isIndicatorVisible && (
                <div className="mapping-rules-run-indicator bg-primary text-white" data-bg-content={binaryGibber}>
                    <span>Rules are running</span>
                </div>
            )}
        </SignalRHub>
    );
};

export default MappingRulesRunIndicator;
