import { isSvgImage } from 'helpers/FileHelper';

interface ImagePreviewProps {
    img: string;
    width: number;
    alt: string;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ img, width, alt }) => {
    return isSvgImage ? (
        <object data={img} style={{ width: width }}></object>
    ) : (
        <img alt={alt} src={img} style={{ width: width }} />
    );
};

export default ImagePreview;
