import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { CheckAllButtonProps, CheckAllStates } from './types';

const stateViews: { [x: number]: FontAwesomeIconProps } = {
    [CheckAllStates.unchecked]: {
        icon: ['far', 'square'],
        className: '',
        title: 'All checkboxes are unchecked',
    },
    [CheckAllStates.checked]: {
        icon: ['fas', 'square-check'],
        className: 'text-primary',
        title: 'All checkboxes are checked',
    },
    [CheckAllStates.undetermined]: {
        icon: ['fas', 'square-minus'],
        className: 'text-warning',
        title: 'Some checkboxes may be unchecked',
    },
};

const CheckAllButton = ({ checkAllState, toggleCheckAll }: CheckAllButtonProps) => {
    return (
        <div
            data-testid={'check-all-checkbox'}
            onClick={() =>
                toggleCheckAll(
                    checkAllState === CheckAllStates.unchecked ? CheckAllStates.checked : CheckAllStates.unchecked
                )
            }>
            <FontAwesomeIcon size={'2x'} {...stateViews[checkAllState]} />
        </div>
    );
};

export default CheckAllButton;
