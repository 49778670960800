import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import { requestFlagDispositionRules } from 'api/RepairProcedureApi';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import 'components/Shared/Table/Table.scss';
import { OemId } from 'helpers/OemId';
import { PrimaryButton } from 'oemiq-common';
import useModal from 'hooks/useModal';
import ModalOperations, { OperationSite, OperationTypes } from 'components/Shared/ModalOperations/ModalOperations';
import ModalAddNewRule from 'components/Shared/ModalAddNewRule/ModalAddNewRule';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { requestRunAllFlagDispositionRules, requestSwapFlagDispositionOrder } from 'api/FlagDispositionApi';
import { requestAreAllOperationsFinishedSinceTime } from 'api/RepairProcedures/RepairProcedureOperationApi';
import { requestFlagTerms, setFlagDispositionRuleActiveStatus } from 'api/FlaggerApi';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangle } from '@fortawesome/pro-regular-svg-icons';

export enum SwapDirection {
    UP = -1,
    DOWN = 1,
}

const mapFlagDispositionRuleField = (key: string): string => {
    return match(key)
        .with('ProcedureTitle', () => 'Procedure Title')
        .with('OneTimeUseFlagTerm', () => '1xID Flag Term')
        .with('Group', () => 'Groups')
        .otherwise(() => key);
};

const mapFlagDispositionRuleOperator = (key: string): string => {
    return match(key)
        .with('Equals', () => 'equals')
        .with('NotEquals', () => 'not equals')
        .with('Contains', () => 'contains')
        .with('NotContains', () => 'not contains')
        .otherwise(() => key);
};

const mapFlagDispositionRuleValue = (
    groups,
    types,
    oneTimeUseFlagTerms,
    key: string,
    ruleDefinitionValue: string
): string[] => {
    const getValueByName = (
        items: unknown[],
        key: string,
        valueKey: string,
        ruleDefinitionValue: string
    ): string | undefined => {
        const value = parseInt(ruleDefinitionValue, 10);
        const matchingItem = items.find(item => item[key] === value);
        return matchingItem ? matchingItem[valueKey] : undefined;
    };

    return match(key)
        .with('Group', () => {
            let parsed = JSON.parse(ruleDefinitionValue);
            if (!Array.isArray(parsed)) {
                parsed = [parsed];
            }

            return parsed.map(v => getValueByName(groups, 'regionId', 'regionFriendlyName', v));
        })
        .with('Type', () => [getValueByName(types, 'oemIqSectionId', 'oemIqSectionName', ruleDefinitionValue)])
        .with('OneTimeUseFlagTerm', () => [
            `${getValueByName(oneTimeUseFlagTerms, 'id', 'term', ruleDefinitionValue)} (ID ${ruleDefinitionValue})`,
        ])
        .otherwise(() => [ruleDefinitionValue]);
};

const operationCyclicCheckTime = 10000;

const FlagDispositionRules = () => {
    const { groups, types } = useContext(MappingDefinitionsContext);
    const { notifications } = useContext(NotificationsContext);
    const { oemId } = useParams();

    const [oneTimeUseFlagTerms, setOneTimeUseFlagTerms] = useState();
    useEffect(() => {
        const getFlagTerms = async () => {
            const flagTerms = await requestFlagTerms(oemId);
            setOneTimeUseFlagTerms(flagTerms);
        };
        getFlagTerms();
    }, [oemId]);

    const parsedOemId = parseInt(oemId) as OemId;

    const [flagDispositionRules, setFlagDispositionRules] = useState([]);
    const [isRunAllRulesDisabled, setIsRunAllRulesDisabled] = useState(false);

    useEffect(() => {
        const fetchFlagDispositionRules = async () => {
            const response = await requestFlagDispositionRules(parsedOemId);
            response.sort((a, b) => a.order - b.order);
            setFlagDispositionRules(response);
        };

        fetchFlagDispositionRules();
    }, [parsedOemId]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const getAreAllOperationsFinished = async () => {
            const finished = await requestAreAllOperationsFinishedSinceTime(parsedOemId, operationCyclicCheckTime, [
                OperationTypes.ApplyFlagDispositionRules,
            ]);
            setIsRunAllRulesDisabled(!finished);
            timeout = setTimeout(getAreAllOperationsFinished, operationCyclicCheckTime);
        };

        getAreAllOperationsFinished();
        return () => timeout && clearTimeout(timeout);
    }, [parsedOemId]);

    const runAllRules = useCallback(async () => {
        try {
            setIsRunAllRulesDisabled(true);
            await requestRunAllFlagDispositionRules(parsedOemId);
            notifications.pushSuccess('All rules will run for entire oem');
        } catch (error) {
            notifications.pushExceptionDanger(error);
        }
    }, [notifications, parsedOemId]);

    interface SwapRulesResponse {
        rules: { ruleId: number; ruleOrder: number }[];
    }

    const swapRuleOrder = useCallback(async (ruleIdToChange: number, swapDirection: SwapDirection) => {
        const result = (await requestSwapFlagDispositionOrder(ruleIdToChange, swapDirection)) as SwapRulesResponse;
        setFlagDispositionRules(rules => {
            result.rules.forEach(change => {
                const rule = rules.find(rule => rule.ruleId == change.ruleId);
                if (rule) {
                    rule.order = change.ruleOrder;
                }
            });
            rules.sort((rule1, rule2) => rule1.order - rule2.order);
            return [...rules];
        });
    }, []);

    const {
        isModalOpen: isOperationsModalOpen,
        openModal: openOperationsModal,
        closeModal: closeOperationsModal,
    } = useModal();

    const {
        isModalOpen: isAddNewRuleOpen,
        openModal: openAddNewRuleModal,
        closeModal: closeAddNewRuleModal,
    } = useModal();

    const handleNewDispositionRule = flagDispositionRule => {
        setFlagDispositionRules(prev => [...prev, flagDispositionRule]);
    };

    return (
        <div className="pt-3 pe-3 ps-3 d-flex flex-column">
            <div className="d-flex mb-3">
                <h2 className="flex-grow-1">Flag Disposition Rules</h2>
                <PrimaryButton
                    id="open-add-new-rule"
                    className="me-3"
                    type="button"
                    onClick={openAddNewRuleModal}
                    disabled={false}>
                    Add New Rule
                </PrimaryButton>
                <PrimaryButton
                    id="open-run-all-modal"
                    className="me-3"
                    type="button"
                    onClick={runAllRules}
                    disabled={isRunAllRulesDisabled}>
                    Run All Rules
                </PrimaryButton>
                <PrimaryButton id="open-operations-modal" className="me-3" type="button" onClick={openOperationsModal}>
                    Operations
                </PrimaryButton>
            </div>
            <table className="table sticky-header">
                <thead>
                    <tr>
                        <th id="swapOrder"></th>
                        <th id="ruleId">Id</th>
                        <th id="order">Order</th>
                        <th id="ruleConditions">Rule Conditions</th>
                        <th id="ruleDisposition">Disposition</th>
                        <th id="ruleIsActive">Is Active</th>
                    </tr>
                </thead>
                <tbody>
                    {flagDispositionRules.map((rule, index) => (
                        <tr key={rule.ruleId}>
                            <td headers="swapOrder">
                                <div className="d-flex flex-column align-items-center">
                                    <button
                                        className="btn btn-outline-primary py-0"
                                        title="Move rule up"
                                        disabled={index === 0}
                                        onClick={() => swapRuleOrder(rule.ruleId, SwapDirection.UP)}>
                                        <FontAwesomeIcon icon={faTriangle} />
                                    </button>
                                    <button
                                        className="btn btn-outline-primary py-0 mt-2"
                                        title="Move rule down"
                                        disabled={index === flagDispositionRules.length - 1}
                                        onClick={() => swapRuleOrder(rule.ruleId, SwapDirection.DOWN)}>
                                        <FontAwesomeIcon icon={faTriangle} className="fa-flip-vertical" />
                                    </button>
                                </div>
                            </td>
                            <td headers="ruleId">{rule.ruleId}</td>
                            <td headers="order">{rule.order}</td>
                            <td headers="ruleConditions">
                                {rule.flagDispositionRuleDefinitions.map(ruleDefinition => {
                                    return (
                                        <span
                                            key={ruleDefinition.ruleDefinitionId}
                                            className={`badge me-2 mt-2 pe-2 rule-label`}>
                                            <span>{mapFlagDispositionRuleField(ruleDefinition.field)}</span>
                                            <span className="badge rule-condition-pill">
                                                {mapFlagDispositionRuleOperator(ruleDefinition.operator)}
                                            </span>
                                            {mapFlagDispositionRuleValue(
                                                groups,
                                                types,
                                                oneTimeUseFlagTerms,
                                                ruleDefinition.field,
                                                ruleDefinition.value
                                            ).map(v => (
                                                <span className="badge rule-pill" key={v}>
                                                    {v}
                                                </span>
                                            ))}
                                        </span>
                                    );
                                })}
                            </td>
                            <td headers="ruleDisposition">
                                {rule.oneTimeUseFlagDisposition.oneTimeUseFlagDispoistionName}
                            </td>
                            <td headers="ruleIsActive">
                                <ButtonGroup className="flag-disposition-rules-btngroup px-2 py-0">
                                    <ToggleButton
                                        value={'newButton'}
                                        id="ruleIsActiveNewButton"
                                        className="btn-filter"
                                        title="Default status"
                                        type="radio"
                                        name="radio"
                                        disabled={rule.isActive !== null}
                                        variant={rule.isActive === null ? 'secondary' : 'outline-secondary'}
                                        checked={rule.isActive === null}>
                                        {'New (off)'}
                                    </ToggleButton>
                                    <ToggleButton
                                        value={'onButton'}
                                        id="ruleIsActiveOnButton"
                                        className="btn-filter"
                                        title="Sets flag disposition rule as active"
                                        type="radio"
                                        name="radio"
                                        variant={rule.isActive === true ? 'success' : 'outline-secondary'}
                                        checked={rule.isActive}
                                        onClick={event => {
                                            event.preventDefault();
                                            setFlagDispositionRuleActiveStatus({ ruleId: rule.ruleId, isActive: true });
                                            setFlagDispositionRules(prevRules =>
                                                prevRules.map(prevRule =>
                                                    prevRule.ruleId === rule.ruleId
                                                        ? { ...prevRule, isActive: true }
                                                        : prevRule
                                                )
                                            );
                                        }}>
                                        {'On'}
                                    </ToggleButton>
                                    <ToggleButton
                                        value={'offButton'}
                                        id="ruleIsActiveOffButton"
                                        className="btn-filter"
                                        title="Sets flag disposition rule as not active"
                                        type="radio"
                                        name="radio"
                                        variant={rule.isActive === false ? 'danger' : 'outline-secondary'}
                                        checked={rule.isActive === false}
                                        onClick={event => {
                                            event.preventDefault();
                                            setFlagDispositionRuleActiveStatus({
                                                ruleId: rule.ruleId,
                                                isActive: false,
                                            });
                                            setFlagDispositionRules(prevRules =>
                                                prevRules.map(prevRule =>
                                                    prevRule.ruleId === rule.ruleId
                                                        ? { ...prevRule, isActive: false }
                                                        : prevRule
                                                )
                                            );
                                        }}>
                                        {'Off'}
                                    </ToggleButton>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {isOperationsModalOpen && (
                <ModalOperations
                    oemId={parsedOemId}
                    operationTypes={[OperationTypes.ApplyFlagDispositionRules]}
                    modalType={OperationSite.FlagDispositionRules}
                    isOperationsModalOpen={isOperationsModalOpen}
                    closeOperationsModalCallback={closeOperationsModal}
                />
            )}
            {isAddNewRuleOpen && (
                <ModalAddNewRule
                    oemId={parsedOemId}
                    isOpen={isAddNewRuleOpen}
                    oneTimeUseFlagTerms={oneTimeUseFlagTerms}
                    toggle={closeAddNewRuleModal}
                    addNewDispositionRuleCallback={handleNewDispositionRule}
                />
            )}
        </div>
    );
};

export default FlagDispositionRules;
