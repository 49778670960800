import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUpRightFromSquare,
    faSitemap,
    faBuilding,
    faUser,
    faCheck,
    faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ApiService from 'api/ApiService';
import './GlobalSearchBar.scss';
import { ApiEndpoints } from 'api/config/ApiEndpoints';
import { CUST_TOOLS } from 'components/locations/ManageCustomers/ManageCustomersConstants';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
    IGlobalSearchResult,
    IOrgLocation,
    IOrganization,
    IUserBasicData,
} from 'components/locations/ManageCustomers/types';
import { globalSearchType } from '../Utils/Constants';
import useClickOutside from 'hooks/useClickOutside';

const searchScopeType = {
    global: 'Global',
    currentPage: 'Current Page',
};

interface IGlobalSearch {
    inPageSearchCallback: (searchText: string) => void;
    globalSearchRef: React.MutableRefObject<HTMLInputElement>;
}

type SearchResult = {
    key: number;
    title: string;
    desc: string;
    navigationPath: string;
};

const GlobalSearchBar = ({ inPageSearchCallback, globalSearchRef }: IGlobalSearch) => {
    const [searchScope, setSearchScope] = useState(searchScopeType.global);
    const [searchText, setSearchText] = useState('');
    const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
    const [searchResponse, setSearchReseponse] = useState<IGlobalSearchResult>();
    const [searchRequestInProgress, setSearchRequestInProgress] = useState<boolean>(false);
    const navigate = useNavigate();
    const abortControllerRef = useRef<AbortController>();
    const [searchParams, setSearchParams] = useSearchParams();
    const { compRef, isComponentVisible, setIsComponentVisible } = useClickOutside(showSearchResult);
    const { pathname } = useLocation();

    const triggerSearch = useCallback(async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort('Request aborted by user');
        }
        abortControllerRef.current = new AbortController();

        if (searchScope === searchScopeType.currentPage) {
            inPageSearchCallback(searchText);
            setShowSearchResult(false);
            return;
        }

        if (searchText) {
            setIsComponentVisible(true);
            setShowSearchResult(true);
            setSearchRequestInProgress(true);
            const res = await ApiService.post(
                ApiEndpoints.organization.globalSearch,
                {
                    searchText: searchText,
                    searchType: globalSearchType.all,
                },
                abortControllerRef.current.signal
            );
            setSearchRequestInProgress(false);
            setSearchReseponse(res);
        } else {
            setShowSearchResult(false);
            setSearchRequestInProgress(false);
            setSearchReseponse(null);
        }
    }, [searchText, searchScope, inPageSearchCallback, setIsComponentVisible]);

    useEffect(() => {
        triggerSearch();
    }, [searchText, searchScope, inPageSearchCallback, triggerSearch]);

    const searchResult = useMemo(() => {
        return searchResponse;
    }, [searchResponse]);

    const handleSearchInput = (input: string) => {
        setSearchText(input);
    };

    const handleClearSearch = () => {
        if (searchParams.has('searchKey')) searchParams.delete('searchKey');
        if (searchParams.has('search')) searchParams.delete('search');
        setSearchParams(searchParams);
    };

    const handleResultItemClick = (navPath: string, searchKey: number) => {
        navigate(navPath + '?searchKey=' + searchKey);
        setSearchText('');
    };

    const handleMoreResultClick = (navPath: string) => {
        navigate(navPath + '?search=' + searchText);
        setSearchText('');
    };

    const renderSpinner = () => {
        return (
            <div className="text-center">
                <img
                    className="loading-logo"
                    alt="loading-logo"
                    style={{ height: '20px', width: '20px' }}
                    src="/images/oemiq-logo.png"
                />
            </div>
        );
    };

    const renderSearchResultSection = (
        header: string,
        showMoreResultLink: boolean,
        results: SearchResult[],
        navigationPathMoreResults: string,
        icon: IconProp
    ) => {
        return (
            <div
                data-testid={`${header}-card-item`}
                id={`${header}SearchResult`}
                className={header === 'Users' ? '' : 'mb-3'}
                key={header}>
                <div className="d-flex justify-content-between">
                    <span className="text-secondary h6 fs-12px">{header}</span>
                    {showMoreResultLink && results?.length ? (
                        <a
                            role="button"
                            onClick={() => handleMoreResultClick(navigationPathMoreResults)}
                            className="text-decoration-none link-show-more"
                            data-testid="link-show-more">
                            Show more results <FontAwesomeIcon icon={faArrowUpRightFromSquare as IconProp} />
                        </a>
                    ) : null}
                </div>
                <ul className="list-group list-group-flush res-list" data-testid="res-list">
                    {results?.length ? (
                        results.map((r: SearchResult) => (
                            <li
                                data-testid="res-list-item"
                                className="list-group-item border-0 d-flex ps-0 clickable"
                                onClick={() => handleResultItemClick(r.navigationPath, r.key)}
                                key={r.key}>
                                <div className="align-content-center pe-2">
                                    <div className="bg-primary icon-rounded">
                                        <FontAwesomeIcon icon={icon as IconProp} className="text-white" />
                                    </div>
                                </div>
                                <div className="text-break">
                                    <div className="h6 mb-0 res-title" data-testid="res-title">
                                        {r.title}
                                    </div>
                                    <small className="text-secondary res-title" data-testid="res-title">
                                        {r.desc}
                                    </small>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li
                            data-testid="text-no-result"
                            className="list-group-item border-0 text-center small text-secondary text-no-result">
                            No Results Found
                        </li>
                    )}
                </ul>
            </div>
        );
    };

    const getSubtitle = (discAry: string[]) => {
        return discAry.filter(p => p && p.length)?.join(',');
    };

    return (
        <div data-testid="gsearch-bar" className="d-flex position-relative" ref={compRef}>
            <div
                data-testid="gsearch-bar-input-group"
                className="input-group gsearch-bar btn-outline-secondary border rounded">
                <input
                    autoFocus
                    id="gsearch-text"
                    data-testid="gsearch-text"
                    placeholder="Search"
                    type="search"
                    className="form-control gsearch-text border-0"
                    aria-label="Search"
                    value={searchText}
                    onInput={e => handleSearchInput(e.currentTarget.value)}
                    onFocus={() => !isComponentVisible && triggerSearch()}
                    ref={globalSearchRef}
                />
                <span
                    data-testid="gsearch-ddl-text"
                    className="ddl-search text-placeholder d-inline-flex align-items-center dropdown-toggle border-0 p-1 fs-13px"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {searchScope}
                    <FontAwesomeIcon className="ms-1 text-placeholder fs-12px" icon={faChevronDown as IconProp} />
                </span>
                <ul
                    data-testid="gsearch-ddl-list"
                    className="dropdown-menu dropdown-menu-end gsearch-scope-dropdown-menu fs-12px">
                    <li
                        onClick={() => setSearchScope(searchScopeType.global)}
                        className={`clickable dropdown-item${
                            searchScope === searchScopeType.global ? ' bg-light' : ''
                        }`}>
                        <div className="row">
                            <div className="col-2">
                                {searchScope === searchScopeType.global && (
                                    <FontAwesomeIcon icon={faCheck as IconProp} />
                                )}
                            </div>
                            <div className="col">{searchScopeType.global}</div>
                        </div>
                    </li>
                    <li
                        onClick={() => setSearchScope(searchScopeType.currentPage)}
                        className={`clickable dropdown-item${
                            searchScope === searchScopeType.currentPage ? ' bg-light' : ''
                        }`}>
                        <div className="row">
                            <div className="col-2">
                                {searchScope === searchScopeType.currentPage && (
                                    <FontAwesomeIcon icon={faCheck as IconProp} />
                                )}
                            </div>
                            <div className="col">{searchScopeType.currentPage}</div>
                        </div>
                    </li>
                </ul>
            </div>

            {(searchParams.has('searchKey') ||
                (searchParams.has('search') && CUST_TOOLS.orgTool.Path() === pathname)) && (
                <button
                    type="button"
                    id="clearResults"
                    data-testid="clear-results"
                    className="btn btn-sm btn-primary btn-clear ms-1 text-nowrap"
                    onClick={handleClearSearch}>
                    Clear Results
                </button>
            )}
            {isComponentVisible && showSearchResult && (
                <div
                    data-testid="gsearch-card"
                    className="card gsearch-dropdown card-shadow dropdown-menu show position-absolute mt-5 p-3"
                    style={{ minWidth: '100%' }}>
                    {searchRequestInProgress ? (
                        renderSpinner()
                    ) : (
                        <>
                            {renderSearchResultSection(
                                'Organizations',
                                true,
                                searchResult?.organization?.results?.map((r: IOrganization) => {
                                    return {
                                        key: r.organizationId,
                                        title: r.name,
                                        desc: getSubtitle([r.address, r.city, r.state, r.zip]),
                                        navigationPath: `${CUST_TOOLS.orgTool.Path()}`,
                                    };
                                }),
                                `${CUST_TOOLS.orgTool.Path()}`,
                                faSitemap as IconProp
                            )}

                            {renderSearchResultSection(
                                'Locations',
                                true,
                                searchResult?.company?.results?.map((r: IOrgLocation) => {
                                    return {
                                        key: r.companyId,
                                        title: r.companyName,
                                        desc: getSubtitle([r.address, r.city, r.state, r.zip]),
                                        navigationPath: `${CUST_TOOLS.orgTool.Path(r.organizationId)}`,
                                    };
                                }),
                                `${CUST_TOOLS.compTool.Path()}`,
                                faBuilding as IconProp
                            )}

                            {renderSearchResultSection(
                                'Users',
                                true,
                                searchResult?.user?.results?.map((r: IUserBasicData) => {
                                    return {
                                        key: r.userId,
                                        title: `${r.firstName} ${r.lastName}`,
                                        desc: r.email,
                                        navigationPath: `${CUST_TOOLS.orgTool.Path(r.company?.organizationId)}/${
                                            CUST_TOOLS.orgUserTool.route
                                        }`,
                                    };
                                }),
                                `${CUST_TOOLS.orgUserTool.Path()}`,
                                faUser as IconProp
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default GlobalSearchBar;
