import { UserManager, UserManagerEvents, WebStorageStateStore } from 'oidc-client';
import { AuthenticationConfig } from './AuthenticationConfig';
import apiConfig from 'api/config/ApiConfig';

// uncomment below to log oidc info
// Oidc.Log.logger = console;

export const AuthenticationResultStatus = {
    Success: 'success',
    Fail: 'fail',
    Redirect: 'redirect',
} as const;

export class AuthenticationService {
    // By default pop ups are disabled because they don"t work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;
    userManager?: UserManager;

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    async getUser() {
        await this.ensureUserManagerInitialized();

        const user = await this.userManager.getUser();
        return user && user.profile;
    }

    async getAccessToken() {
        await this.ensureUserManagerInitialized();

        let user = await this.userManager.getUser();
        if (user === null) {
            await this.signIn(null);
            user = await this.userManager.getUser();
        }
        //if the token expires in less than 10 minutes or is already expired refresh it.
        if (user.expires_in < 600 || user.expired) {
            try {
                user = await this.userManager.signinSilent();
            } catch (e) {
                //this should cause a new login redirect
                if (user.expired) await this.userManager.removeUser();
            }
        }
        return user && user.access_token;
    }

    async getUserExpiryTimeMs() {
        await this.ensureUserManagerInitialized();

        let user = await this.userManager.getUser();

        if (user.expires_in < 600 || user.expired) {
            try {
                await this.getAccessToken();
                user = await this.userManager.getUser();
            } catch (e) {
                //this should cause a new login redirect
                if (user.expired) {
                    await this.userManager.removeUser();
                    this.userManager.clearStaleState();
                }
            }
        }
        return user && user.expires_at * 1000; //convert to ms
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state) {
        await this.ensureUserManagerInitialized();
        try {
            await this.userManager.signinSilent(this.createArguments(state));
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log('Silent authentication error: ', silentError);

            try {
                if (this._popUpDisabled) {
                    throw new Error(
                        'Popup disabled. Change "AuthorizeService.js:AuthorizeService._popupDisabled" to false to enable it.'
                    );
                }

                await this.userManager.signinPopup(this.createArguments(state));
                return this.success(state);
            } catch (popUpError) {
                if (popUpError.message === 'Popup window closed') {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error('The user closed the window.');
                } else if (!this._popUpDisabled) {
                    console.log('Popup authentication error: ', popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await this.userManager.signinRedirect(this.createArguments(state));
                    return this.redirect();
                } catch (redirectError) {
                    console.log('Redirect authentication error: ', redirectError);
                    return this.error(redirectError);
                }
            }
        }
    }

    async completeSignIn(url: string) {
        try {
            await this.ensureUserManagerInitialized();

            const user = await this.userManager.signinCallback(url);
            return this.success(user && user.state);
        } catch (error) {
            console.log('There was an error signing in: ', error);
            return this.error('There was an error signing in');
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state) {
        await this.ensureUserManagerInitialized();
        try {
            console.log('Signout called....');
            await this.userManager.signoutRedirect(this.createArguments(state));
            return null;
        } catch (redirectSignOutError) {
            console.log('Redirect signout error: ', redirectSignOutError);
            return this.error(redirectSignOutError);
        }
    }

    async completeSignOut(url: string) {
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager.signoutCallback(url);
            return this.success(response);
        } catch (error) {
            console.log(`There was an error trying to log out "${error}".`);
            return this.error(error);
        }
    }

    async subscribe(callback: UserManagerEvents.UserSessionChangedCallback) {
        await this.ensureUserManagerInitialized();

        this.userManager.events.addUserSessionChanged(callback);

        return callback;
    }

    async unsubscribe(callback) {
        await this.ensureUserManagerInitialized();

        this.userManager.events.removeUserSessionChanged(callback);
    }

    createArguments(state) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state) {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    async ensureUserManagerInitialized() {
        if (this.userManager !== undefined) {
            return;
        }

        const authConfig = new AuthenticationConfig(await apiConfig.get());
        const settings = {
            ...authConfig.AUTH_CONFIG,
            userStore: new WebStorageStateStore({
                prefix: 'oemiq',
                store: window.sessionStorage,
            }),
            automaticSilentRenew: true,
            includeIdTokenInSilentRenew: true,
            metadata: {
                ...authConfig.METADATA_OIDC,
            },
        };

        this.userManager = new UserManager(settings);
        this.userManager.clearStaleState();
    }

    static get instance() {
        return authService;
    }
}

const authService = new AuthenticationService();

export default authService;
