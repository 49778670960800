import { ReactModal } from 'oemiq-common';
import GroupTypeBody from './GroupTypeBody/GroupTypeBody';
import GroupTypeFooter from './GroupTypeFooter';
import useGroupTypeModal from './useGroupTypeModal';
import { ESProcedure } from 'components/locations/MappingProcess/Mapper/ESProcedure';

type GroupTypeModalProps = {
    isOpen: boolean;
    groupTypeModalProcedureIds: number[];
    selectedProceduresCount: number;
    resetProcedureIds: () => void;
    setNewGroupListToProcedureByProcedureId: (newGroupList, procedureId) => void;
    procedures: ESProcedure[];
    resetBulkSelection: () => void;
    groupTypeModalBulkType;
    setGroupTypeModalBulkType;
    oemId: number;
    oDataFilter: string;
};

const GroupTypeModal = ({
    isOpen,
    groupTypeModalProcedureIds,
    selectedProceduresCount,
    resetProcedureIds,
    setNewGroupListToProcedureByProcedureId,
    procedures,
    resetBulkSelection,
    groupTypeModalBulkType,
    setGroupTypeModalBulkType,
    oemId,
    oDataFilter,
}: GroupTypeModalProps) => {
    const {
        handleRegionClick,
        handleMapProcedures,
        handleModalToggle,
        selectedTypeIdWithStatusId,
        handleTypeStatusSelection,
        typeSelectionOptions,
        selectedGroupIdsWithStatusId,
    } = useGroupTypeModal(
        groupTypeModalProcedureIds,
        resetProcedureIds,
        setNewGroupListToProcedureByProcedureId,
        procedures,
        resetBulkSelection,
        groupTypeModalBulkType,
        setGroupTypeModalBulkType,
        oemId,
        oDataFilter
    );

    return (
        <ReactModal
            id="group-type-modal"
            className="modal-xl"
            headerComponent={
                <div style={{ fontSize: '1rem', lineHeight: 'normal' }}>
                    {groupTypeModalBulkType !== null ? `Bulk ${groupTypeModalBulkType}` : 'Assign'}
                </div>
            }
            isOpen={isOpen}
            toggle={handleModalToggle}
            bodyComponent={
                <GroupTypeBody
                    selectedProceduresCount={selectedProceduresCount}
                    handleRegionClick={handleRegionClick}
                    selectedTypeIdWithStatusId={selectedTypeIdWithStatusId}
                    handleTypeStatusSelection={handleTypeStatusSelection}
                    typeSelectionOptions={typeSelectionOptions}
                    selectedGroupIdsWithStatusId={selectedGroupIdsWithStatusId}
                />
            }
            footerComponent={
                <GroupTypeFooter handleModalToggle={handleModalToggle} handleMapProcedures={handleMapProcedures} />
            }
        />
    );
};

type SingleGroupTypeModalType = {
    isOpen: boolean;
    closeGroupTypeModal: () => void;
    setNewGroupListToProcedureByProcedureId: (newGroupList, procedureId) => void;
    procedure: ESProcedure;
};

export const SingleGroupTypeModal = ({
    isOpen,
    closeGroupTypeModal,
    setNewGroupListToProcedureByProcedureId,
    procedure,
}: SingleGroupTypeModalType) => {
    return (
        <GroupTypeModal
            isOpen={isOpen}
            groupTypeModalProcedureIds={[procedure.procedureId]}
            resetProcedureIds={closeGroupTypeModal}
            setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
            procedures={[procedure]}
            resetBulkSelection={closeGroupTypeModal}
            selectedProceduresCount={1}
            groupTypeModalBulkType={null}
            setGroupTypeModalBulkType={() => null}
            oemId={null}
            oDataFilter={null}
        />
    );
};

export default GroupTypeModal;
