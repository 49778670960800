import { useContext } from 'react';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import Unauthorized from './Unauthorized';
import { useMatches } from 'react-router-dom';
import { isObject } from 'lodash';

export const AuthorizedElementMatches = ({ children }: { children: JSX.Element }) => {
    const matches = useMatches();
    const restricted = matches.map(m => m.handle).filter(h => isObject(h) && 'resource' in h) as { resource: string }[];
    const { hasAccess, userInfo } = useContext(AccessControlContext);

    return restricted.some(r => !hasAccess(r.resource)) ? userInfo.roles && <Unauthorized /> : children;
};
