import type { CellProps } from 'components/Shared/Table/types';
import { useContext } from 'react';
import { RefreshDashboardRowDataType } from '../types';
import ImportManagerDashboardContext from '../importManagerDashboardContext';

const DownloadTypeCell: React.FC<CellProps> = ({ item }) => {
    const { isLoading, oemRefreshSchedules } = item as RefreshDashboardRowDataType;
    const { importJobDownloadTypes } = useContext(ImportManagerDashboardContext);

    if (isLoading || !oemRefreshSchedules) return <td></td>;

    const id = `import-manager-item-${item.oemId}-downloadtype`;

    return (
        <td className="px-0" style={{ verticalAlign: 'middle' }} id={id}>
            <div className="d-flex flex-column">
                {oemRefreshSchedules.map((schedule, i) => {
                    const scheduleId = `${id}-${schedule.id}`;
                    const downloadType =
                        importJobDownloadTypes.find(t => t.importJobDownloadTypeId === schedule.downloadTypeId)
                            ?.importJobDownloadTypeName ?? '-';
                    return (
                        <div
                            id={scheduleId}
                            key={schedule.id}
                            className={`py-4 d-flex flex-row align-items-center justify-content-center ${
                                i === 0 ? 'border-0' : 'border-top'
                            }`}>
                            {downloadType}
                        </div>
                    );
                })}
            </div>
        </td>
    );
};

export default DownloadTypeCell;
