import { CellProps } from 'components/Shared/Table/types';
import { RefreshDashboardRowDataType } from '../types';

const StatusCell: React.FC<CellProps> = ({ item }) => {
    const { isLoading, lastImportJobSummary: summary } = item as RefreshDashboardRowDataType;

    if (isLoading || !summary) return <td></td>;

    const date = (summary.updatedAt ?? summary.createdAt).substring(0, 10) + ' GMT';
    const status =
        summary.success === true
            ? `Completed ${date}`
            : summary.success === false
            ? `Pending Failures ${date}`
            : `In Progress ${(
                  100 *
                  (!summary.numberOfBooks
                      ? 0
                      : summary.bookSummary
                      ? 1 - summary.bookSummary.inProgress / summary.numberOfBooks
                      : 0)
              ).toFixed(2)}%`;

    const id = `import-manager-item-${item.oemId}-status`;

    return (
        <td style={{ verticalAlign: 'middle' }} id={id}>
            <div className="d-flex flex-row align-items-center">{`${status}`}</div>
        </td>
    );
};

export default StatusCell;
