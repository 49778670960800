import { buildProcedureProperty } from './ProcedureProperty';
import { getCommonProperties, getLastCommonProperties, getCommonFilters } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { ProcedureType } from './ProcedureType';

const OEM_METADATA_PROPERTY = 'latestFordRawProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.system',
        displayName: 'System',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'system',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.group',
        displayName: 'Group',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'group',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.section',
        displayName: 'Section',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'section',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procTitle',
        displayName: 'Proc Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procTitle',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.sectionTitle',
        displayName: 'Section Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'sectionTitle',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.subSectionTitle',
        displayName: 'SubSection Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'subSectionTitle',
        procedureTypes: [ProcedureType.Procedure],
    }),
];

const getFordProcedureMetadata = () =>
    buildOemMetadata({
        oemId: 1,
        oemName: 'Ford',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.fordProcedureId',
                displayName: 'Ford Procedure Id',
                type: PROPERTY_TYPE.number,
                procedureTypes: [ProcedureType.Procedure, ProcedureType.Bulletin],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.oemProcedureId',
                displayName: 'Oem Procedure Id',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure, ProcedureType.Bulletin],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.variantId',
                displayName: 'Variant Id',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.bookCode',
                displayName: 'Book Code',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.market',
                displayName: 'Market',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.language',
                displayName: 'Language',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.year',
                displayName: 'Year',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.modelName',
                displayName: 'Model Name',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.procId',
                displayName: 'Proc Id',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.revisionDate',
                displayName: 'Revision Date',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.type',
                displayName: 'Type',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.procSMGLId',
                displayName: 'Proc SMGL Id',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.bulletinType',
                displayName: 'Bulletin Type',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Bulletin],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.effectiveDate',
                displayName: 'Effective Date',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Bulletin],
            }),
            ...getLastCommonProperties(),
        ],
        ruleKey: 'fordMappingRuleId',
        filters: [...getCommonFilters()],
    });

type FordMappingRule = OemMappingRuleBase & {
    fordMappingRuleId: number;
};

class _FordMappingEngineService implements OemEngineService<FordMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = FORD_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: FordMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: FordMappingRule): Promise<FordMappingRule> => {
        const newRule = { ...rule };
        const fordMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.fordMappingRuleId = fordMappingRuleId;

        return newRule;
    };
}

export const FORD_PROCEDURE_METADATA = getFordProcedureMetadata();
export const FordMappingEngineService = new _FordMappingEngineService();
