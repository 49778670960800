import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OemInfo } from '../types';
import { LoadingContext } from 'components/Layout';
import { requestModelsForOem } from 'api/vehicleInfo';

interface OemItemProps {
    oemInfo: OemInfo;
    disabled: boolean;
    mode;
    badgeText: string;
}
const OemItem = ({ oemInfo, disabled, mode, badgeText }: OemItemProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const handleOemSelection = async (oemId: number) => {
        if (mode?.hasVehicles) {
            incrementLoading();
            let models = [];
            try {
                models = await requestModelsForOem(oemId);
            } finally {
                decrementLoading();
            }
            models.sort((m1, m2) => (m1.modelName < m2.modelName ? -1 : 1));
            models.length && navigate(`${location.pathname}/${oemId}/${models[0].modelId}`);
        } else if (mode?.procedureTools) {
            navigate(`${location.pathname}/${oemId}`);
        } else {
            navigate(`${location.pathname}/${oemId}`);
        }
    };

    return disabled ? (
        <div className="me-3 mb-3 p-2 border-2 rounded-lg height-rem-8 width-rem-10 d-flex flex-column justify-content-between align-items-center flex-shrink-0 opacity-15">
            <div> </div>
            <img
                data-name={`${oemInfo.oemName}-logo`}
                alt={`${oemInfo.oemName}-logo`}
                className="mw-100 h-100"
                src={oemInfo.logo}
            />
            {oemInfo.oemName}
        </div>
    ) : (
        <div
            onClick={() => handleOemSelection(oemInfo.oemId)}
            className="me-5 mb-3 p-2 border-2 rounded-lg height-rem-8 width-rem-10 d-flex flex-column justify-content-between align-items-center flex-shrink-0 card-shadow clickable position-relative"
            // This will not properly work with oemNames containing spaces. Will create a comment to follow up with it
            id={`oem-${oemInfo.oemName}`}>
            <div> </div>
            <img
                data-name={`${oemInfo.oemName}-logo`}
                alt={`${oemInfo.oemName}-logo`}
                className="mw-100 h-100"
                src={oemInfo.logo}
            />
            <span>{oemInfo.oemName}</span>
            {badgeText && (
                <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    data-testid={`${oemInfo.oemName}-notification-count`}>
                    {badgeText}
                </span>
            )}
        </div>
    );
};

export default OemItem;
