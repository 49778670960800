import { memo, useCallback, useContext, useMemo } from 'react';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModal from 'hooks/useModal';
import { match } from 'ts-pattern';
import { SingleGroupTypeModal } from 'components/locations/MappingProcess/OldMapper/OldMapperTable/GroupTypeModal/GroupTypeModal';
import { FormatDateToGMT } from 'helpers/DateHelper';
import { Tooltip } from 'react-tooltip';
import { ColumnContext, useRow } from 'components/Shared/NewDataTable/Contextes';
import { ESProcedure } from 'components/locations/MappingProcess/Mapper/ESProcedure';
import { useVehicleInfo } from 'contexts/VehicleInfoContext';
import { groupModelsByYears } from 'components/locations/MappingProcess/utils/groupModelsByYears';
import { Expand, getVehiclesCellState } from './logic/getVehiclesCellState';
import { Button } from 'react-bootstrap';
import './PropertyType.Components.scss';
import { FilteredVehicle } from './types/FilteredVehicle';

function getBadgeClassName(mappingStatusId: number) {
    return match(mappingStatusId)
        .with(1, () => 'text-bg-primary')
        .with(2, () => 'text-bg-success')
        .with(3, () => 'text-bg-danger')
        .otherwise(() => 'text-bg-primary');
}

export const BasicViewValueComponent = memo(
    ({
        getValue,
        isMultipleProvider,
    }: {
        getValue: (row: ESProcedure, columnId: string) => string;
        isMultipleProvider: (columnId: string) => boolean;
    }) => {
        const row = useRow<ESProcedure>();
        const id = useContext(ColumnContext);
        const value = getValue(row, id);
        const multiple = useMemo(() => isMultipleProvider(id), [id, isMultipleProvider]);

        if (multiple) {
            return (
                <>
                    {value &&
                        (Array.isArray(value) ? value : value.split(',')).map(s => (
                            <span className="badge text-bg-primary me-1" key={s}>
                                {s}
                            </span>
                        ))}
                </>
            );
        }

        return <span>{value}</span>;
    }
);
BasicViewValueComponent.displayName = 'BasicViewValueComponent';

export const ProcedureDisplayViewValueComponent = memo(({ onClick }: { onClick: (row: ESProcedure) => unknown }) => {
    const row = useRow<ESProcedure>();
    const value = row.procedureTitle;
    return (
        <a onClick={() => onClick(row)} className="link-primary" style={{ cursor: 'pointer' }}>
            {value}
        </a>
    );
});
ProcedureDisplayViewValueComponent.displayName = 'ProcedureDisplayViewValueComponent';

export const ProcedureIdAndHistoryComponent = memo(({ onHistoryClick }: { onHistoryClick }) => {
    const row = useRow<ESProcedure>();
    const handleClick = useCallback(
        () =>
            onHistoryClick({
                procedureId: row.procedureId,
                procedureTitle: row.procedureTitle,
            }),
        [onHistoryClick, row.procedureId, row.procedureTitle]
    );
    return (
        <>
            <div>
                {row.procedureId} <FontAwesomeIcon className="clickable" icon="history" onClick={handleClick} />
            </div>
            {row.isStale && (
                <>
                    <div className="d-flex">
                        <FontAwesomeIcon
                            icon={['fas', 'cloud-arrow-up']}
                            size="2xl"
                            className="m-auto"
                            data-tooltip-id="procedureSyncTooltip"
                        />
                        <Tooltip id="procedureSyncTooltip" style={{ zIndex: 1010 }}>
                            Procedure mapping data is synchronizing
                        </Tooltip>
                    </div>
                </>
            )}
        </>
    );
});
ProcedureIdAndHistoryComponent.displayName = 'ProcedureIdAndHistoryComponent';

export const GroupDisplayViewValueComponent = memo(
    ({
        enabled,
        setNewGroupListToProcedureByProcedureId,
    }: {
        enabled: boolean;
        setNewGroupListToProcedureByProcedureId: (newGroupList, procedureId) => void;
    }) => {
        const row = useRow<ESProcedure>();
        const {
            isModalOpen: isGroupTypeModalOpen,
            openModal: openGroupTypeModal,
            closeModal: closeGroupTypeModal,
        } = useModal();

        const mappingDefinitions = useContext(MappingDefinitionsContext);
        const groups = row?.stageArea.groups;
        const isRemovalPending = row?.stageArea.isDeleted && !row.isDeleted;
        const isRemoved = row?.stageArea.isDeleted && row.isDeleted;

        if (enabled && !isRemovalPending && !isRemoved && (!mappingDefinitions?.groups?.length || !groups?.length)) {
            return (
                <button
                    type="button"
                    className="btn btn-outline-primary mb-1"
                    style={{ fontSize: '12px', borderRadius: '24px' }}
                    onClick={enabled && !isRemovalPending && !isRemoved ? () => openGroupTypeModal() : null}>
                    Select
                    {isGroupTypeModalOpen && (
                        <SingleGroupTypeModal
                            isOpen={isGroupTypeModalOpen}
                            procedure={row}
                            closeGroupTypeModal={closeGroupTypeModal}
                            setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                        />
                    )}
                </button>
            );
        }

        return (
            <div>
                {groups.map(group => (
                    <span
                        className={`badge ${getBadgeClassName(group.mappingStatusId)} me-1`}
                        key={group.groupId}
                        onClick={enabled && !isRemovalPending && !isRemoved ? () => openGroupTypeModal() : null}
                        style={enabled && !isRemovalPending && !isRemoved ? { cursor: 'pointer' } : {}}>
                        {mappingDefinitions.groups.find(g => g.value === group.groupId)?.label ?? group.groupId}
                        <span className="badge text-bg-light rounded-pill ms-1">{group.mappingRuleId ?? 'M'}</span>
                    </span>
                ))}
                {isGroupTypeModalOpen && (
                    <SingleGroupTypeModal
                        isOpen={isGroupTypeModalOpen}
                        procedure={row}
                        closeGroupTypeModal={closeGroupTypeModal}
                        setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    />
                )}
            </div>
        );
    }
);
GroupDisplayViewValueComponent.displayName = 'GroupDisplayViewValueComponent';

export const GroupIdDisplayViewValueComponent = memo(
    ({
        enabled,
        setNewGroupListToProcedureByProcedureId,
    }: {
        enabled: boolean;
        setNewGroupListToProcedureByProcedureId: (newGroupList, procedureId) => void;
    }) => {
        const row = useRow<ESProcedure>();
        const groups = row?.stageArea.groups;
        const {
            isModalOpen: isGroupTypeModalOpen,
            openModal: openGroupTypeModal,
            closeModal: closeGroupTypeModal,
        } = useModal();
        const isRemovalPending = row?.stageArea.isDeleted && !row.isDeleted;
        const isRemoved = row?.stageArea.isDeleted && row.isDeleted;

        return (
            <div>
                {groups.map(group => (
                    <span
                        className={`badge ${getBadgeClassName(group.mappingStatusId)} me-1`}
                        key={group.groupId}
                        onClick={enabled && !isRemovalPending && !isRemoved ? () => openGroupTypeModal() : null}
                        style={enabled && !isRemovalPending && !isRemoved ? { cursor: 'pointer' } : {}}>
                        {group.groupId}
                    </span>
                ))}
                {isGroupTypeModalOpen && (
                    <SingleGroupTypeModal
                        isOpen={isGroupTypeModalOpen}
                        procedure={row}
                        closeGroupTypeModal={closeGroupTypeModal}
                        setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    />
                )}
            </div>
        );
    }
);
GroupIdDisplayViewValueComponent.displayName = 'GroupIdDisplayViewValueComponent';

export const TypeNameViewValueComponent = memo(
    ({
        enabled,
        setNewGroupListToProcedureByProcedureId,
    }: {
        enabled: boolean;
        setNewGroupListToProcedureByProcedureId: (newGroupList, procedureId) => void;
    }) => {
        const row = useRow<ESProcedure>();
        const value = row.stageArea.type.typeId;
        const {
            isModalOpen: isGroupTypeModalOpen,
            openModal: openGroupTypeModal,
            closeModal: closeGroupTypeModal,
        } = useModal();

        const { mappingRuleId, mappingStatusId } = row.stageArea.type;
        const mappingDefinitions = useContext(MappingDefinitionsContext);
        if (!mappingDefinitions.groups || !mappingDefinitions.groups.length) return undefined;
        const oemiqType = mappingDefinitions.types.find(type => type.value === value);
        const isRemovalPending = row?.stageArea.isDeleted && !row.isDeleted;
        const isRemoved = row?.stageArea.isDeleted && row.isDeleted;

        return value ? (
            <span
                className={`badge ${getBadgeClassName(mappingStatusId)} me-1`}
                onClick={enabled && !isRemovalPending && !isRemoved ? () => openGroupTypeModal() : null}
                style={enabled && !isRemovalPending && !isRemoved ? { cursor: 'pointer' } : {}}>
                {oemiqType && oemiqType.text}
                {isGroupTypeModalOpen && (
                    <SingleGroupTypeModal
                        isOpen={isGroupTypeModalOpen}
                        procedure={row}
                        closeGroupTypeModal={closeGroupTypeModal}
                        setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    />
                )}
                <span className="badge text-bg-light rounded-pill ms-1">{mappingRuleId ?? 'M'}</span>
            </span>
        ) : enabled && !isRemovalPending && !isRemoved ? (
            <button
                type="button"
                className="btn btn-outline-primary mb-1"
                style={{ fontSize: '12px', borderRadius: '24px' }}
                onClick={enabled && !isRemovalPending && !isRemoved ? () => openGroupTypeModal() : null}>
                Select
                {isGroupTypeModalOpen && (
                    <SingleGroupTypeModal
                        isOpen={isGroupTypeModalOpen}
                        procedure={row}
                        closeGroupTypeModal={closeGroupTypeModal}
                        setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    />
                )}
            </button>
        ) : null;
    }
);
TypeNameViewValueComponent.displayName = 'TypeNameViewValueComponent';

export const TypeIdViewValueComponent = memo(
    ({
        enabled,
        setNewGroupListToProcedureByProcedureId,
    }: {
        enabled: boolean;
        setNewGroupListToProcedureByProcedureId: (newGroupList, procedureId) => void;
    }) => {
        const row = useRow<ESProcedure>();
        const { mappingStatusId } = row.stageArea.type;
        const {
            isModalOpen: isGroupTypeModalOpen,
            openModal: openGroupTypeModal,
            closeModal: closeGroupTypeModal,
        } = useModal();
        const isRemovalPending = row?.stageArea.isDeleted && !row.isDeleted;
        const isRemoved = row?.stageArea.isDeleted && row.isDeleted;

        return (
            <span
                className={`badge ${getBadgeClassName(mappingStatusId)} me-1`}
                onClick={enabled && !isRemovalPending && !isRemoved ? () => openGroupTypeModal() : null}
                style={enabled && !isRemovalPending && !isRemoved ? { cursor: 'pointer' } : {}}>
                {row.stageArea.type.typeId}
                {isGroupTypeModalOpen && (
                    <SingleGroupTypeModal
                        isOpen={isGroupTypeModalOpen}
                        procedure={row}
                        closeGroupTypeModal={closeGroupTypeModal}
                        setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    />
                )}
            </span>
        );
    }
);
TypeIdViewValueComponent.displayName = 'TypeIdViewValueComponent';

export const GmtDateDisplayViewValueComponent = memo(
    ({ getValue }: { getValue: (row: ESProcedure, columnId: string) => string }) => {
        const row = useRow<ESProcedure>();
        const id = useContext(ColumnContext);
        const gmtDate = FormatDateToGMT(getValue(row, id));
        return <span>{gmtDate}</span>;
    }
);
GmtDateDisplayViewValueComponent.displayName = 'GmtDateDisplayViewValueComponent';

export const VehicleComponent = ({
    filteredVehicles,
    expandedProcedures,
    onExpandProcedureClick,
}: {
    filteredVehicles: FilteredVehicle[] | undefined;
    expandedProcedures: number[];
    onExpandProcedureClick: (procedureId: number) => void;
}) => {
    const vehicleInfo = useVehicleInfo();
    const row = useRow<ESProcedure>();

    const procedureVehicle = row.vehicles;
    const procedureId = row.procedureId;
    const expandSupported = !!expandedProcedures && !!onExpandProcedureClick;

    if (!Array.isArray(procedureVehicle) || procedureVehicle.length === 0) return null;

    const vehicleYearRanges = groupModelsByYears(procedureVehicle);
    const cellExpand =
        !expandSupported || expandedProcedures.includes(procedureId) ? Expand.Expanded : Expand.Collapsed;

    const { vehicles, buttonLabel } = getVehiclesCellState({
        vehicleYearRanges,
        vehicleInfo,
        filteredVehicles,
        cellExpand,
    });

    return (
        <>
            <ul className="vehicle-list">
                {vehicles.map((vehicle, idx) => {
                    const isHighlighted = vehicle.length > 0 && vehicle[0] === '*';
                    const vehicleName = isHighlighted ? vehicle.slice(1) : vehicle;
                    return isHighlighted ? (
                        <li key={idx} className={'highlight-vehicle'}>
                            {vehicleName}
                        </li>
                    ) : (
                        <li key={idx}>{vehicleName}</li>
                    );
                })}
            </ul>
            {expandSupported && buttonLabel && (
                <Button
                    size="sm"
                    variant="outline-primary"
                    className="py-0 m-0"
                    onClick={() => {
                        onExpandProcedureClick(row.procedureId);
                    }}>
                    {buttonLabel}
                </Button>
            )}
        </>
    );
};
