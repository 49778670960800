import { useContext } from 'react';
import { Link, useMatches } from 'react-router-dom';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import MappingRulesRunIndicator from './MappingRulesRunIndicator/MappingRulesRunIndicator';
import { isObject } from 'lodash';
import { HalloweenOver } from './HalloweenOver';
import classNames from 'classnames';
import { FeatureFlagName, useFeatureFlag } from 'contexts/FeatureFlagsContext';

const Nav = () => {
    const { userInfo } = useContext(AccessControlContext);
    const matches = useMatches();
    const crumbs = matches.map(m => m.handle).filter(h => isObject(h) && 'crumb' in h) as {
        crumb: () => JSX.Element;
        newStyleCrumb: boolean;
    }[];

    const newStyleCrumb = crumbs.some(c => c.newStyleCrumb);
    const isHalloweenMode = useFeatureFlag(FeatureFlagName.HalloweenMode);

    return (
        <header className="sticky-top" style={{ zIndex: 1030 }}>
            <nav
                id="header-navbar"
                className="d-flex align-items-center justify-content-between navbar navbar-expand-sm navbar-dark bg-primary">
                <div className="d-flex align-items-center">
                    <Link
                        className="navbar-brand ms-2"
                        to="/"
                        title="Repair Deck Home"
                        data-testid="navigation-bar-brand">
                        {isHalloweenMode ? (
                            <img src="/images/repairdeck-logo-halloween.svg" alt="Repair Deck" />
                        ) : (
                            <img src="/images/repairdeck-logo.svg" alt="Repair Deck" />
                        )}
                    </Link>
                    {newStyleCrumb ? (
                        <ol className="breadcrumb">
                            {crumbs.map((c, i) => (
                                <li
                                    key={i}
                                    className={classNames('breadcrumb-item', 'text-nowrap', {
                                        active: i === crumbs.length - 1,
                                    })}>
                                    {c.crumb()}
                                </li>
                            ))}
                        </ol>
                    ) : (
                        crumbs[crumbs.length - 1]?.crumb()
                    )}
                </div>
                <div className="d-flex flex-row">
                    <MappingRulesRunIndicator />
                    {userInfo.userId && (
                        <div
                            id="logged-username"
                            className="text-white p-2">{`${userInfo.firstName} ${userInfo.lastName}`}</div>
                    )}
                </div>
            </nav>
            {isHalloweenMode && <HalloweenOver />}
        </header>
    );
};

export default Nav;
