import { FC } from 'react';
import { LabelsCellOptions, CellProps } from '../types';
import calculateClass from './Helpers/calculateClass';
import './labels.scss';

const Labels: (options?: LabelsCellOptions) => FC<CellProps> = options => {
    const LabelsComponent = ({ item, header }: CellProps) => {
        return (
            <td headers={header.id} className={calculateClass(options, item)} title={header.title}>
                {options.labels &&
                    options.labels(item).map((l, i) => (
                        <span
                            key={i}
                            className={`badge default-label me-2 mt-2 pe-2 ${
                                options.labelClass ? options.labelClass : 'text-bg-primary'
                            }`}>
                            {l.labelText}
                            {Array.isArray(l.pillTexts) &&
                                l.pillTexts.map((p, j) => (
                                    <span
                                        key={j}
                                        className={`badge ${options.pillsClass ? options.pillsClass : 'default-pill'}`}>
                                        {p}
                                    </span>
                                ))}
                        </span>
                    ))}
            </td>
        );
    };
    return LabelsComponent;
};

export default Labels;
