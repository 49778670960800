import React from 'react';
import { BaseSyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
    RuleableOperatorBackendEnumValue,
    ColumnChangeAction,
    ColumnChangeCommand,
    ColumnData,
    ColumnValues,
} from './types';
import './RuleableValuePicker.scss';
import OperatorDropdown from './OperatorDropdown';
import { escapeRegExp } from 'lodash';

const LOAD_ALL_THRESHOLD = 500;

interface RulableValuePickerProps {
    columnData: ColumnData;
    columnValues: ColumnValues;
    onChange: (e) => void;
    onLoadAllClick: (e: string) => void;
    onLoadAll: (e: string) => void;
    className: string;
}

const RulableValuePicker = ({
    columnData,
    columnValues,
    onChange,
    onLoadAll,
    onLoadAllClick,
    ...props
}: RulableValuePickerProps) => {
    const [containsText, setContainsText] = useState('');
    const isContainsFilter = useMemo(
        () => columnData.operator != RuleableOperatorBackendEnumValue.Equal,
        [columnData.operator]
    );

    const isValueEnabled = useCallback(
        v => {
            return isContainsFilter
                ? new RegExp(escapeRegExp(columnData.value), 'i').test(v)
                    ? columnData.operator === RuleableOperatorBackendEnumValue.Contains
                    : columnData.operator === RuleableOperatorBackendEnumValue.NotContains
                : v !== null && v.toUpperCase() === columnData.value.toUpperCase();
        },
        [columnData.operator, columnData.value, isContainsFilter]
    );

    const valuesSorted = useMemo(
        () =>
            columnValues.values.sort((a, b) => {
                if (columnData.value) {
                    const isAEnabled = isValueEnabled(a);
                    const isBEnabled = isValueEnabled(b);

                    if (isAEnabled && !isBEnabled) return -1;
                    if (!isAEnabled && isBEnabled) return 1;
                }

                if (a === null) {
                    return -1;
                }
                if (b === null) {
                    return 1;
                }

                if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                } else if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            }),
        [columnData.value, columnValues.values, isValueEnabled]
    );

    interface OnColumnChange {
        action: ColumnChangeAction;
        operator: RuleableOperatorBackendEnumValue;
        value: string;
    }

    const handleColumnChange = useCallback(
        (onColumnChange: OnColumnChange) => {
            const columnChangeCommand: ColumnChangeCommand = {
                action: onColumnChange.action,
                propertyName: columnData.rulePropertyName,
                id: columnData.id,
                operator: onColumnChange.operator,
                value: onColumnChange.value,
            };
            onChange(columnChangeCommand);
            if (onColumnChange.action === ColumnChangeAction.SetOperator) {
                setContainsText('');
            }
        },
        [columnData, onChange]
    );

    const loadAll = useCallback(() => {
        onLoadAll(columnData.id);
    }, [columnData.id, onLoadAll]);

    useEffect(() => {
        if (columnValues.isLoadAll && columnValues.count > LOAD_ALL_THRESHOLD && columnValues.values.length === 0) {
            loadAll();
        }
    }, [columnValues.count, columnValues.isLoadAll, columnValues.values.length, loadAll]);

    const displayValue = (value: string) => {
        switch (value) {
            case null:
                return <i className="null-value">{'null'}</i>;
            case '':
                return <i className="empty-value">{'empty_value'}</i>;
            default:
                return value;
        }
    };

    return (
        <div
            {...props}
            className={`card property-column position-relative ${props.className || ''}`}
            data-testid="column">
            {columnValues.isLoading && (
                <div className="loading-overlay text-center bg-light">
                    <div className="spinner-border creator-column-spinner" role="status" />
                </div>
            )}
            <div className="card-header">
                <h6>{columnData.label}</h6>
                <OperatorDropdown
                    operator={columnData.operator}
                    containsText={containsText}
                    onChange={handleColumnChange}
                />
            </div>
            {isContainsFilter && (
                <div className="p-3">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Phrase"
                            value={containsText}
                            onChange={(e: BaseSyntheticEvent) => setContainsText(e.target.value)}
                            onKeyUp={e =>
                                e.key === 'Enter' &&
                                containsText &&
                                handleColumnChange({
                                    action: ColumnChangeAction.SetContainsText,
                                    value: containsText,
                                    operator: columnData.operator,
                                })
                            }
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={() =>
                                    containsText &&
                                    handleColumnChange({
                                        action: ColumnChangeAction.SetContainsText,
                                        value: containsText,
                                        operator: columnData.operator,
                                    })
                                }>
                                &crarr;
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {columnValues.count <= LOAD_ALL_THRESHOLD || columnValues.values.length > 0 ? (
                <ul className="list-group list-group-flush space-visible">
                    {valuesSorted.map(v => (
                        <li
                            data-testid={`${
                                columnData.hasActiveFilter ? (isValueEnabled(v) ? 'selected' : 'grayed') : 'white'
                            }-value`}
                            key={v}
                            role="button"
                            className={`list-group-item list-group-item-action ${
                                columnData.hasActiveFilter
                                    ? isValueEnabled(v)
                                        ? 'list-group-item-primary'
                                        : 'list-group-item-secondary'
                                    : ''
                            }`}
                            onClick={() => {
                                if (v === null) return;
                                handleColumnChange({
                                    action: ColumnChangeAction.SetValue,
                                    value: v,
                                    operator: RuleableOperatorBackendEnumValue.Equal,
                                });
                            }}>
                            {displayValue(v)}
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="alert alert-warning text-center" role="alert">
                    {columnValues.count} values
                    <br />
                    Too many to display
                    <br />
                    <button className="btn btn-primary mt-2" onClick={() => onLoadAllClick(columnData.id)}>
                        Load All
                    </button>
                </div>
            )}
        </div>
    );
};

export default React.memo(RulableValuePicker);
