import { PrimaryButton } from 'oemiq-common';

interface EditScheduleModalBodyProps {
    isActive: boolean;
    downloadType: string;
    nextScheduledRun: string;
    cronInterval: string;
    switchDisabled: boolean;
    pauseButtonDisabled: boolean;
    onIsActiveCheckboxChange: () => void;
    onPauseButtonClick: () => void;
}

const EditScheduleModalBody: React.FC<EditScheduleModalBodyProps> = ({
    isActive,
    downloadType,
    nextScheduledRun,
    cronInterval,
    switchDisabled,
    pauseButtonDisabled,
    onIsActiveCheckboxChange,
    onPauseButtonClick,
}) => {
    const readableDate = nextScheduledRun.replace(/T/gi, ' ').substring(0, 19) + ' GMT';

    return (
        <div className="container">
            <div className="row d-flex align-items-center my-3">
                <div className="col-5 text-end">Download Type</div>
                <div className="col fw-bold">{downloadType}</div>
            </div>
            <div className="row d-flex align-items-center my-3">
                <div className="col-5 text-end">Run Refresh Import Automatically</div>
                <div className="col switch switch-sm d-flex align-items-center">
                    <input
                        id="is-active-switch-edit-refresh-schedule-modal"
                        data-testid="is-active-switch-edit-refresh-schedule-modal"
                        className="switch"
                        type="checkbox"
                        checked={isActive}
                        disabled={switchDisabled}
                        onChange={onIsActiveCheckboxChange}
                    />
                    <label htmlFor="is-active-switch-edit-refresh-schedule-modal" />
                </div>
            </div>
            <div className="row d-flex align-items-center my-3">
                <div className="col-5 text-end">Next Scheduled Run</div>
                <div className="col d-flex flex-row align-items-center">
                    <span className="fw-bold me-3">{readableDate}</span>
                    <PrimaryButton
                        id="pause-button-edit-refresh-schedule-modal"
                        data-testid="pause-button-edit-refresh-schedule-modal"
                        className="btn-sm"
                        disabled={pauseButtonDisabled}
                        onClick={onPauseButtonClick}>
                        Pause
                    </PrimaryButton>
                </div>
            </div>
            <div className="row d-flex align-items-center my-3">
                <div className="col-5 text-end">Cron Interval</div>
                <div className="col fw-bold">{cronInterval}</div>
            </div>
        </div>
    );
};

export default EditScheduleModalBody;
