import { ReactNode } from 'react';
import ReactModal from '../ReactModal';

interface IConfirmationModal {
    show: boolean;
    header: string;
    body: ReactNode;
    onConfirmCallback: () => void;
    onCancelCallback: (res: boolean) => void;
    confirmButtonText: string;
}

const ConfirmationModal = ({
    show,
    header,
    body,
    onConfirmCallback,
    onCancelCallback,
    confirmButtonText,
}: IConfirmationModal) => {
    return (
        <ReactModal
            isOpen={show}
            headerComponent={<div>{header ?? 'Are you sure?'}</div>}
            bodyComponent={<div>{body}</div>}
            footerComponent={
                <div>
                    <button
                        type="button"
                        className="btn btn-sm btn-danger h-100"
                        data-bs-dismiss="modal"
                        onClick={() => onCancelCallback && onCancelCallback(false)}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-sm btn-primary ms-3 h-100" onClick={onConfirmCallback}>
                        {confirmButtonText ?? 'Confirm'}
                    </button>
                </div>
            }
            container={undefined}
        />
    );
};

export default ConfirmationModal;
