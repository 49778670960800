import { FC, useState, useEffect } from 'react';
import { CellProps, TextAreaCellOptions } from './types';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import { TextArea } from 'oemiq-common';
import { VehiclePublishExceptionTrackerFieldEnum } from 'helpers/VehicleProcessHelper';

const PublishExceptionNotes: (options?: TextAreaCellOptions) => FC<CellProps> = options => {
    const PublishExceptionNotesComponent = ({ value = '', header, item, index }: CellProps) => {
        const [note, setNote] = useState(item.note);
        const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
            options.textArea.onBlur(
                item ? item : {},
                VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_NOTE.Id,
                e.target.value
            );
        };
        const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setNote(e.target.value);
        };
        useEffect(() => {
            setNote(value ? value : '');
        }, [value]);
        return (
            <td
                headers={header.id}
                className={calculateClass(options, item)}
                id={`${header.id}${index}`}
                title={header.title}
                key={`${item.vehicleId}${header}`}>
                {item.bookId ? (
                    <></>
                ) : (
                    <TextArea
                        id={'form-control-input-note' + item.vehicleId}
                        className={'vehicle-publish-textarea custom-textarea-scroll'}
                        rows={3}
                        placeholder="Add notes not exceeding 50 characters"
                        value={note}
                        onChange={handleOnChange}
                        textAreaProps={{
                            onBlur: handleBlur,
                            maxLength: 50,
                            disabled: options.textArea.disabled,
                        }}
                    />
                )}
            </td>
        );
    };
    return PublishExceptionNotesComponent;
};
export default PublishExceptionNotes;
