import { useCallback, useContext, useState } from 'react';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import ReactSelect from 'react-select';

type GroupListNamesComponentProps = {
    filter;
    setFilterValue;
};

const GroupListNamesComponent = ({ filter, setFilterValue }: GroupListNamesComponentProps) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);
    const [inputValue, setInputValue] = useState((filter.valueList && filter.valueList[0]) || '');

    const handleChange = useCallback(
        e => {
            const v = e.target.value;
            const val = mappingDefinitions.groups.filter(g => g.label.match(new RegExp(v, 'i')));
            setInputValue(v);
            setFilterValue({
                value: val,
                valueList: [v],
            });
        },
        [mappingDefinitions.groups, setFilterValue]
    );

    return filter.operator.value !== 'nameContains' ? (
        <ReactSelect
            className="mb-2"
            placeholder="Choose value"
            options={mappingDefinitions.groups}
            isMulti={true}
            isDisabled={!filter.id || !filter.operator}
            value={filter.value}
            onChange={value => setFilterValue({ value, valueList: value.map(v => v.label) })}
        />
    ) : (
        mappingDefinitions?.groups?.length && (
            <input type="text" className="form-control" onChange={handleChange} value={inputValue} />
        )
    );
};

export default GroupListNamesComponent;
