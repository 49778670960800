import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BackAndForward.scss';

type BackAndForwardProps = {
    procedureId: number;
    navCallback: (item: number) => void;
};

interface HistoryState {
    items: number[];
    index: number;
}

const BackAndForward = ({ procedureId, navCallback }: BackAndForwardProps) => {
    const [history, setHistory] = useState<HistoryState>({ items: [], index: -1 });

    const incIndex = () => {
        setHistory({
            items: history.items,
            index: ++history.index,
        });
    };

    const decIndex = () => {
        setHistory({
            items: history.items,
            index: --history.index,
        });
    };

    const runCallback = () => {
        navCallback(history.items[history.index]);
    };

    const handleBackClick = () => {
        decIndex();
        runCallback();
    };

    const handleFwdClick = () => {
        incIndex();
        runCallback();
    };

    useEffect(() => {
        setHistory(h => {
            if (procedureId !== h.items[h.index]) {
                const items = [...h.items.slice(0, h.index + 1), procedureId];
                const index = ++h.index;

                return { items, index };
            }
            return h;
        });
    }, [procedureId]);

    return (
        <div>
            <button
                data-testid="procedure-backward-btn"
                className="btn btn link arrow-link"
                onClick={handleBackClick}
                disabled={history.index < 1}>
                <FontAwesomeIcon icon={['fas', 'chevron-left']} className="text-primary" />
            </button>
            <button
                data-testid="procedure-forward-btn"
                className="btn btn link arrow-link"
                onClick={handleFwdClick}
                disabled={history.index > history.items.length - 2}>
                <FontAwesomeIcon icon={['fas', 'chevron-right']} className="text-primary" />
            </button>
        </div>
    );
};

export default BackAndForward;
