import { FC } from 'react';
import FloatingMenu from 'components/Shared/FloatingMenu/FloatingMenu';
import { ActionFloatingMenuCellProps, CellProps } from '../types';
import calculateClass from './Helpers/calculateClass';

const ActionFloatingMenuCell = ({
    floatingMenuId,
    labelId,
    options,
    floatingMenuClass = '',
}): FC<ActionFloatingMenuCellProps> => {
    const FloatingMenuComponent = ({ idKey, item, header, index, cellEventCallback }: CellProps) => {
        const buttons = options.buttons.map((b, i) => {
            const textWithoutSpace = b.text.split(' ').join('-');
            return (
                (!b.show || b.show(item)) && (
                    <button
                        key={i}
                        title={b.title}
                        id={`${textWithoutSpace}-${b.id}${index}`}
                        role={textWithoutSpace}
                        data-testid={`${textWithoutSpace}-${b.id}${index}`}
                        className={`btn btn-sm ${b.class}`}
                        onClick={() =>
                            cellEventCallback({
                                id: item[idKey] as string,
                                actionId: b.id,
                                index,
                                item,
                            })
                        }
                        disabled={b.disabled && b.disabled(item)}>
                        {b.component ? b.component(item) : b.text && <span className="me-2">{b.text}</span>}
                    </button>
                )
            );
        });

        return (
            <td headers={header.id} className={calculateClass(options, item)}>
                <FloatingMenu
                    floatingMenuId={`${floatingMenuId}${index}`}
                    labelId={`${labelId}${index}`}
                    floatingMenuClass={floatingMenuClass}
                    items={buttons}
                />
            </td>
        );
    };

    return FloatingMenuComponent;
};

export default ActionFloatingMenuCell;
