import { Link, RouteObject } from 'react-router-dom';
import Oems from 'components/Oem/Oems';
import ToolSelection from 'components/locations/Home/ToolSelection';
import BooksListMapper from 'components/BookList/BooksListMapper';
import OldMapperTool from './OldMapper/OldMapperTool';
import RulesRunnerTool from 'components/locations/MappingProcess/RulesRunner/RulesRunnerTool';
import MapperTool from './Mapper/MapperTool';
import PositionStatementList from 'components/locations/MappingProcess/PositionStatements/PositionStatementList';
import MappingTrainingTool from 'components/locations/MappingProcess/MappingTraining/MappingTrainingTool';
import RulesCreatorTool from 'components/locations/MappingProcess/RulesCreator/RulesCreatorTool';
import { getRedirectOemRoute } from 'components/Navigation/utils/RedirectOemRoute';
import { ProcedureType } from 'hooks/OemModels/MetaModel/ProcedureType';
import MappingProcessNavigation from 'components/Navigation/MappingProcessNavigation';
import { OemBookNameBreadcrumb } from 'components/Navigation/items/Procedures/OemBookNameBreadcrumb';
import { MappingToolBreadcrumb } from 'components/Navigation/items/Procedures/MappingToolBreadcrumb';
import classNames from 'classnames';

const buildMappingProcessRoutes = (): RouteObject[] => {
    return [
        {
            index: true,
            element: (
                <ToolSelection
                    tools={[
                        {
                            id: null,
                            path: '/mapping-process/mapper',
                            text: 'Mapper',
                            secondText: 'Procedures and TSBs',
                            icon: 'car-side',
                            tileDescription: 'Tool for filtering repair procedures and TSBs',
                            access: 'tool.mappingProcess.procedures',
                        },
                        {
                            id: null,
                            path: '/mapping-process/rules-creator',
                            text: 'Mapping Rules Creator',
                            secondText: 'Procedures and TSBs',
                            icon: 'clipboard',
                            tileDescription: 'Tool for creating mapping rules',
                            access: 'tool.mappingProcess.rulesCreator',
                        },
                        {
                            id: null,
                            path: '/mapping-process/rules-runner',
                            text: 'Mapping Rules Runner',
                            secondText: 'Procedures and TSBs',
                            icon: 'chevron-circle-right',
                            tileDescription: 'Tool for running mapping rules',
                            access: 'tool.mappingProcess.rulesRunner',
                        },
                        {
                            id: null,
                            path: '/mapping-process/mapper-old',
                            text: 'Mapper (old)',
                            secondText: null,
                            icon: 'skull-crossbones',
                            tileDescription:
                                '(DEPRECATED - Use Procedures) Tool for mapping groups and a type to procedures',
                            access: 'tool.mappingProcess.mapper',
                        },
                        {
                            id: null,
                            path: '/mapping-process/position-statements',
                            text: 'Position Statements',
                            secondText: null,
                            icon: 'file-pdf',
                            tileDescription: 'Tool for uploading new Position Statements.',
                            access: 'tool.mappingProcess.positionStatements',
                        },
                        {
                            id: null,
                            path: '/mapping-process/mapping-training',
                            text: 'Mapping Training',
                            secondText: null,
                            icon: 'award',
                            tileDescription:
                                'Tool for creating new test manuals for data to training on mapping without effecting live systems',
                            access: 'tool.mappingProcess.mappingTraining',
                        },
                    ]}
                />
            ),
            handle: {
                crumb: () => <MappingProcessNavigation mappingTool={null} />,
            },
        },
        {
            path: 'mapper-old',
            handle: {
                resource: 'tool.mappingProcess.mapper',
            },
            children: [
                {
                    index: true,
                    element: <Oems />,
                    handle: {
                        crumb: () => <MappingProcessNavigation mappingTool="mapper-old" />,
                    },
                },
                {
                    path: ':oemId',
                    children: [
                        {
                            index: true,
                            element: <BooksListMapper procedureTools={false} />,
                            handle: {
                                crumb: () => <MappingProcessNavigation mappingTool="mapper-old" />,
                            },
                        },
                        {
                            path: ':bookId',
                            element: <OldMapperTool />,
                            handle: {
                                crumb: () => <MappingProcessNavigation mappingTool="mapper-old" />,
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: 'rules-creator',
            handle: {
                resource: 'tool.mappingProcess.rulesCreator',
            },
            children: [
                {
                    index: true,
                    element: <Oems />,
                    handle: {
                        crumb: () => <MappingProcessNavigation mappingTool="rules-creator" />,
                    },
                },
                {
                    path: ':oemId',
                    children: [
                        {
                            index: true,
                            element: <RulesCreatorTool />,
                            handle: {
                                crumb: () => <MappingProcessNavigation mappingTool="rules-creator" />,
                            },
                        },
                    ],
                },
                ...getRedirectOemRoute(),
            ],
        },
        {
            path: 'rules-runner',
            handle: {
                resource: 'tool.mappingProcess.rulesRunner',
            },
            children: [
                {
                    index: true,
                    element: <Oems />,
                    handle: {
                        crumb: () => <MappingProcessNavigation mappingTool="rules-runner" />,
                    },
                },
                {
                    path: ':oemId',
                    children: [
                        {
                            index: true,
                            element: <RulesRunnerTool />,
                            handle: {
                                crumb: () => <MappingProcessNavigation mappingTool="rules-runner" />,
                            },
                        },
                    ],
                },
                ...getRedirectOemRoute(),
            ],
        },
        {
            path: 'mapper', // mapper
            handle: {
                resource: 'tool.mappingProcess.procedures',
                crumb: () => (
                    <Link to="/mapping-process/mapper" className={classNames('text-decoration-none', 'text-white')}>
                        Mapper
                    </Link>
                ),
                newStyleCrumb: true,
            },
            children: [
                {
                    index: true,
                    element: <Oems mode={{ procedureTools: true }} />,
                },
                {
                    path: ':oemId',
                    loader: () => true,
                    handle: { crumb: () => <OemBookNameBreadcrumb /> },
                    children: [
                        {
                            path: 'all',
                            element: <MapperTool />,
                            handle: {
                                crumb: () => <MappingToolBreadcrumb procedureType={null} />,
                            },
                        },
                        {
                            path: 'procedures',
                            element: <MapperTool defaultProcedureType={ProcedureType.Procedure} />,
                            handle: {
                                crumb: () => <MappingToolBreadcrumb procedureType={ProcedureType.Procedure} />,
                            },
                        },
                        {
                            path: 'bulletins',
                            element: <MapperTool defaultProcedureType={ProcedureType.Bulletin} />,
                            handle: {
                                crumb: () => <MappingToolBreadcrumb procedureType={ProcedureType.Bulletin} />,
                            },
                        },
                        {
                            path: 'position-statements',
                            element: <MapperTool defaultProcedureType={ProcedureType.PositionStatement} />,
                            handle: {
                                crumb: () => <MappingToolBreadcrumb procedureType={ProcedureType.PositionStatement} />,
                            },
                        },
                        {
                            index: true,
                            element: <BooksListMapper procedureTools={true} />,
                        },
                    ],
                },
                ...getRedirectOemRoute('all'),
                ...getRedirectOemRoute('procedures'),
                ...getRedirectOemRoute('bulletins'),
                ...getRedirectOemRoute('position-statements'),
            ],
        },
        {
            path: 'position-statements',
            handle: {
                resource: 'tool.mappingProcess.positionStatements',
            },
            children: [
                {
                    index: true,
                    element: <Oems mode={{ hasProcedureVehicles: true }} />,
                    handle: {
                        crumb: () => <MappingProcessNavigation mappingTool="position-statements" />,
                    },
                },
                {
                    path: ':oemId',
                    children: [
                        {
                            index: true,
                            element: <PositionStatementList />,
                            handle: {
                                crumb: () => <MappingProcessNavigation mappingTool="position-statements" />,
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: 'mapping-training',
            handle: {
                resource: 'tool.mappingProcess.mappingTraining',
            },
            children: [
                {
                    index: true,
                    element: <MappingTrainingTool />,
                    handle: {
                        crumb: () => <MappingProcessNavigation mappingTool="mapping-training" />,
                    },
                },
            ],
        },
    ];
};

export const MappingProcessRoute: RouteObject = {
    path: 'mapping-process',
    children: buildMappingProcessRoutes(),
    handle: {
        resource: 'tool.mappingProcess',
        crumb: () => (
            <Link to="/mapping-process" className={classNames('text-decoration-none', 'text-white')}>
                Mapping Process
            </Link>
        ),
    },
};
