import { FC } from 'react';
import { LocalDateCellOptions, CellProps } from '../types';
import calculateClass from './Helpers/calculateClass';
import './localdate.scss';

const z = n => (n < 10 ? `0${n}` : n);

const LocalDate: (options?: LocalDateCellOptions) => FC<CellProps> = options => {
    const LocalDateComponent = ({ value, item, header }: CellProps) => {
        const d = new Date(value);
        const dateText = value ? `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}` : ``;

        return (
            <td
                headers={header.id}
                className={`${calculateClass(options, item)} default-local-date`}
                title={header.title}>
                {dateText}
            </td>
        );
    };
    return LocalDateComponent;
};

export default LocalDate;
